import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";

export const SkeltonCardHeader: React.FC = () => {
  return (
    <CenterBox
      justifyContent="start"
      flexDirection="row"
      height="fit-content"
      gap="lg"
    >
      <Skeleton height={50} width={50} borderRadius={200} />
      <Column gap="lg">
        <Row gap="sm">
          <Skeleton width={70} height={25} borderRadius={200} />
          <Skeleton width={5} height={5} borderRadius={200} />
          <Skeleton width={70} height={25} />
        </Row>
        <Row gap="sm">
          <Skeleton width={60} height={29} borderRadius={200} />
          <Skeleton width={60} height={29} borderRadius={200} />
          <Skeleton width={60} height={29} borderRadius={200} />
          <Skeleton width={60} height={29} borderRadius={200} />
          <Skeleton width={60} height={29} borderRadius={200} />
          <Skeleton width={60} height={29} borderRadius={200} />
        </Row>
      </Column>
    </CenterBox>
  );
};
