import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const VOTE_POLL = graphql(`
  mutation VotePoll($input: VotePollInput!) {
    votePoll(input: $input) {
      id
    }
  }
`);
export const useVotePoll = () => {
  const [votePoll, { data, loading, error }] = useMutation(VOTE_POLL);
  return {
    votePoll,
    data,
    loading,
    error,
  };
};
