"use client";
import { useState } from "react";
import { Box, Column, PrimaryHoverBox2, Row, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { Tag } from "@/components/Tag";

import Link from "next/link";
import { MentionType } from "@/gql/graphql";
import { getRelativePostTime } from "@/utils/getRelativePostTime";

import styled from "styled-components";

export type CardHeaderProps = {
  schoolLogo: string;
  postBy: string;
  universityName: string;
  tags: {
    mentionId: string;
    mentionType: MentionType;
    tag: string;
    endIndex: number;
    startIndex: number;
  }[];
  createdAt: Date;
  postId: string;
  authorId: string;
  isDeletedFeed?: boolean;
  handlePostDelete: () => void;
  handleJustDeleted?: (PostId: string) => void;
  isReply?: boolean;
  showOptions?: boolean;
  isSharedAsStory?: boolean;
};

export const StoriesCardHeader: React.FC<CardHeaderProps> = ({
  schoolLogo,
  postBy,
  universityName,
  tags,
  createdAt,
  isSharedAsStory = false,
}) => {
  const [openOtherTags, setOpenOtherTags] = useState(false);
  const tagsToShow = Array.from(new Set(tags?.slice(0, 1)));
  const tagsHidden = Array.from(new Set(tags?.slice(1)));

  const _createdAt = getRelativePostTime(createdAt);

  return (
    <Row
      alignContent="center"
      alignItems="center"
      maxWidth={"100%"}
      gap={["xs", "lg"]}
    >
      <Link href={`/school/${universityName}/overview`}>
        <Box
          backgroundImage={`url('${schoolLogo}')`}
          minWidth={[45, 50]}
          minHeight={[45, 50]}
          backgroundSize={"contain"}
          backgroundColor="white"
          backgroundRepeat="no-repeat"
          backgroundPosition={"center"}
          border={`1px solid ${BASE_COLORS.primaryLight}`}
          borderRadius={"full"}
        ></Box>
      </Link>
      <Column
        gap={tagsToShow.length === 0 ? ["0", "0"] : ["xs", "sm"]}
        width={["86%", "100%"]}
        alignItems={"center"}
      >
        <Row
          color="primary"
          width="100%"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Column
            justifyContent={"center"}
            alignItems={"start"}
            gap={["xs", "sm"]}
          >
            {postBy ? (
              <Row gap={["sm", "md"]} alignItems={"center"}>
                <Text
                  size={[14, 16]}
                  borderRadius={"xl"}
                  bg="primary"
                  color="white"
                  px={"md"}
                  py={"xxs"}
                >
                  {postBy}
                </Text>
                <Box
                  border={`1px solid ${BASE_COLORS.primary}`}
                  bg={"primary"}
                  borderRadius={"full"}
                  height={5}
                  width={5}
                ></Box>
                <Text color="grey" weight="medium">
                  {_createdAt}
                </Text>
              </Row>
            ) : null}

            <Link
              href={`/school/${universityName}/overview`}
              style={{
                textDecoration: "none",
                color: `${BASE_COLORS.primary}`,
              }}
            >
              <Text
                size={[14, 16]}
                color={isSharedAsStory ? "black" : "foreground"}
              >
                {universityName}
              </Text>
            </Link>
          </Column>
        </Row>
        <Box alignItems={"center"} width={"100%"} overflow={"visible"}>
          <Row
            width={"100%"}
            gap={["xs", "sm"]}
            alignItems={"center"}
            display={["none !important", "flex !important"]}
            position="relative"
          >
            {tagsToShow.map((item, index) => (
              <Tag
                key={index}
                tag={item.tag}
                id={item.mentionId}
                type={item.mentionType}
              />
            ))}
            {tagsHidden.length > 0 && (
              <Box>
                <Text color="primary" style={{ cursor: "pointer" }} zIndex={1}>
                  {tagsHidden.length > 1
                    ? `+ ${tagsHidden.length} others`
                    : `+ 1 other`}
                </Text>
                {openOtherTags && (
                  <Box
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    position="absolute"
                    // maxHeight={"100px"}
                    width="fit-content"
                    top={"110%"}
                    left={0}
                    zIndex={2}
                    backgroundColor="background"
                    border={`1px solid ${BASE_COLORS.grey}`}
                    borderRadius="md"
                    boxShadow={BASE_COLORS.lightblackShadow}
                    padding="md"
                    gap={10}
                  >
                    {tagsHidden.map((item, index) => (
                      <Tag
                        key={index}
                        tag={item.tag}
                        id={item.mentionId}
                        type={item.mentionType}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Row>
        </Box>
      </Column>
    </Row>
  );
};
export const ImageContainer = styled.div<{
  normalImage: string;
  hoverImage: string;
}>`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.normalImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${PrimaryHoverBox2}:hover & {
    background-image: url(${(props) => props.hoverImage});
  }
`;
