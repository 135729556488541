"use client";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Box,
  Column,
  OptionsContainer,
  PrimaryHoverBox,
  PrimaryHoverBox2,
  Row,
  Text,
} from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { Options } from "@/utils/constants";
import { Tag } from "@/components/Tag";

import Link from "next/link";
import { tags } from "@/utils/types";
import { MentionType } from "@/gql/graphql";
import { getRelativePostTime } from "@/utils/getRelativePostTime";
import { useModal } from "@/hooks/useModal";
import { BouncyModal } from "@/components/styled/BouncyModal";

import { Auth } from "@/app/auth/Auth";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import Image from "next/image";
import { DeletePostForm } from "../DeletePostForm";
import styled from "styled-components";
import { ReportPostForm } from "../ReportPostForm";
import { RestorePostForm } from "../RestorePostForm";
import { useUser } from "@/hooks/useUser";
import { SuperDeleteForm } from "../SuperDeleteForm";

export type CardHeaderProps = {
  schoolLogo: string;
  postBy: string;
  universityName: string;
  tags: {
    mentionId: string;
    mentionType: MentionType;
    tag: string;
    endIndex: number;
    startIndex: number;
  }[];
  createdAt: Date;
  postId: string;
  authorId: string;
  isDeletedFeed?: boolean;
  handlePostDelete: () => void;
  handleJustDeleted?: (PostId: string) => void;
  isReply?: boolean;
  showOptions?: boolean;
};

export const CardHeader: React.FC<CardHeaderProps> = ({
  schoolLogo,
  postBy,
  universityName,
  tags,
  createdAt,
  postId,
  authorId,
  handlePostDelete,
  handleJustDeleted,
  isDeletedFeed,
  showOptions = true,
}) => {
  const [OpenOptions, setOpenOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [renderModalType, setRenderModalType] = useState("");
  const [openOtherTags, setOpenOtherTags] = useState(false);
  const tagsToShow = Array.from(new Set(tags?.slice(0, 1)));
  const tagsHidden = Array.from(new Set(tags?.slice(1)));
  const { isLoggedin } = useIsLoggedIn();
  const { isOpen, toggleModal } = useModal();
  const _createdAt = getRelativePostTime(createdAt);
  const { data } = useUser();

  const openOptions = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenOptions(!OpenOptions);
  };

  const renderModalContent = (): ReactNode => {
    switch (renderModalType) {
      case "Delete Post":
        return (
          <DeletePostForm
            id={postId}
            toggleModal={toggleModal}
            authorId={authorId}
            handlePostDelete={handlePostDelete}
            handleJustDeleted={handleJustDeleted}
          />
        );
      case "Report Post":
        return <ReportPostForm id={postId} toggleModal={toggleModal} />;
      case "Restore Post":
        return <RestorePostForm id={postId} toggleModal={toggleModal} />;
      case "Super Delete":
        return <SuperDeleteForm id={postId} toggleModal={toggleModal} />;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Row
      alignContent="center"
      alignItems="center"
      maxWidth={"100%"}
      gap={["xs", "lg"]}
    >
      <Link href={`/school/${universityName}/overview`}>
        <Box
          backgroundImage={`url('${schoolLogo}')`}
          minWidth={[45, 50]}
          minHeight={[45, 50]}
          backgroundSize={"contain"}
          backgroundColor="white"
          backgroundRepeat="no-repeat"
          backgroundPosition={"center"}
          border={`1px solid ${BASE_COLORS.primaryLight}`}
          borderRadius={"full"}
        ></Box>
      </Link>
      <Column
        gap={tagsToShow.length === 0 ? ["0", "0"] : ["xs", "sm"]}
        width={["86%", "100%"]}
        alignItems={"center"}
      >
        <Row
          color="primary"
          width="100%"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Column
            justifyContent={"center"}
            alignItems={"start"}
            gap={["xs", "sm"]}
          >
            {postBy ? (
              <Row gap={["sm", "md"]} alignItems={"center"}>
                <Text
                  size={[14, 16]}
                  borderRadius={"xl"}
                  bg="primary"
                  color="white"
                  px={"md"}
                  py={"xxs"}
                >
                  {postBy}
                </Text>
                <Box
                  border={`1px solid ${BASE_COLORS.primary}`}
                  bg={"primary"}
                  borderRadius={"full"}
                  height={5}
                  width={5}
                ></Box>
                <Text color="grey" weight="medium">
                  {_createdAt}
                </Text>
              </Row>
            ) : null}

            <Link
              href={`/school/${universityName}/overview`}
              style={{
                textDecoration: "none",
                color: `${BASE_COLORS.primary}`,
              }}
            >
              <Text size={[14, 16]}>{universityName}</Text>
            </Link>
          </Column>
          {showOptions ? (
            <Box position="relative">
              <PrimaryHoverBox
                width={30}
                height={30}
                borderRadius="xl"
                onClick={openOptions}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  backgroundImage={`url("/assets/images/svgimages/notificationOptionsLogo.svg")`}
                  width={19}
                  height={3}
                  backgroundRepeat="no-repeat"
                  backgroundSize="contain"
                  backgroundPosition="center"
                ></Box>
              </PrimaryHoverBox>
              <OptionsContainer
                open={OpenOptions}
                ref={optionsRef}
                position="absolute"
                border={`1px solid ${BASE_COLORS.grey}`}
                width={["50vw", "220px"]}
                borderRadius="md"
                backgroundColor="background"
                right={0}
                top={"75%"}
                boxShadow={BASE_COLORS.lightblackShadow}
                overflow="hidden"
                zIndex={99}
                maxHeight={OpenOptions ? `${Options.length * 100}` : `${0}`}
              >
                {Options.map((option, index) => {
                  if (!isLoggedin && option.forGuestUser) {
                    return (
                      <PrimaryHoverBox2
                        key={index}
                        borderBottom={
                          index < Options.length - 1
                            ? `1px solid ${BASE_COLORS.grey}`
                            : undefined
                        }
                        px="md"
                        py="md"
                        border={"1px solid red"}
                        onClick={() => {
                          if (option.text !== "Not Interested") {
                            setRenderModalType(option.text);
                            toggleModal();
                          }
                        }}
                      >
                        <Row gap="4xl" alignItems="center">
                          <Text
                            size={[16, 18]}
                            weight="medium"
                            color="foreground"
                          >
                            {option.text}
                          </Text>
                          <ImageContainer
                            normalImage={option.normalImage}
                            hoverImage={option.hoverImage}
                          />
                        </Row>
                      </PrimaryHoverBox2>
                    );
                  } else if (
                    isLoggedin &&
                    isDeletedFeed &&
                    data?.me.activePremiumPlan?.type ===
                      "RUSHLINK_PREMIUM_PLUS" &&
                    option.text !== "Delete Post"
                  ) {
                    return (
                      <PrimaryHoverBox2
                        key={index}
                        borderBottom={
                          index < Options.length - 1
                            ? `1px solid ${BASE_COLORS.grey}`
                            : undefined
                        }
                        px="md"
                        py="md"
                        onClick={() => {
                          if (option.text !== "Not Interested") {
                            setRenderModalType(option.text);
                            toggleModal();
                          }
                        }}
                      >
                        <Row gap="4xl" alignItems="center">
                          <Text
                            size={[16, 18]}
                            weight="medium"
                            color="foreground"
                          >
                            {option.text}
                          </Text>
                          <ImageContainer
                            normalImage={option.normalImage}
                            hoverImage={option.hoverImage}
                          />
                        </Row>
                      </PrimaryHoverBox2>
                    );
                  } else if (
                    isLoggedin &&
                    !isDeletedFeed &&
                    option.text !== "Super Delete" &&
                    option.text !== "Restore Post"
                  ) {
                    return (
                      <PrimaryHoverBox2
                        key={index}
                        borderBottom={
                          index < Options.length - 1
                            ? `1px solid ${BASE_COLORS.grey}`
                            : undefined
                        }
                        px="md"
                        py="md"
                        onClick={() => {
                          if (option.text !== "Not Interested") {
                            setRenderModalType(option.text);
                            toggleModal();
                          }
                        }}
                      >
                        <Row gap="4xl" alignItems="center">
                          <Text
                            size={[16, 18]}
                            weight="medium"
                            color="foreground"
                          >
                            {option.text}
                          </Text>
                          <ImageContainer
                            normalImage={option.normalImage}
                            hoverImage={option.hoverImage}
                          />
                        </Row>
                      </PrimaryHoverBox2>
                    );
                  } else if (
                    isLoggedin &&
                    isDeletedFeed &&
                    data?.me.activePremiumPlan?.type === "RUSHLINK_PREMIUM" &&
                    option.text !== "Super Delete" &&
                    option.text !== "Restore Post" &&
                    option.text !== "Delete Post"
                  ) {
                    return (
                      <PrimaryHoverBox2
                        key={index}
                        borderBottom={
                          index < Options.length - 1
                            ? `1px solid ${BASE_COLORS.grey}`
                            : undefined
                        }
                        px="md"
                        py="md"
                        onClick={() => {
                          if (option.text !== "Not Interested") {
                            setRenderModalType(option.text);
                            toggleModal();
                          }
                        }}
                      >
                        <Row gap="4xl" alignItems="center">
                          <Text
                            size={[16, 18]}
                            weight="medium"
                            color="foreground"
                          >
                            {option.text}
                          </Text>
                          <ImageContainer
                            normalImage={option.normalImage}
                            hoverImage={option.hoverImage}
                          />
                        </Row>
                      </PrimaryHoverBox2>
                    );
                  }
                  return null;
                })}
              </OptionsContainer>
            </Box>
          ) : null}
        </Row>
        <Box alignItems={"center"} width={"100%"} overflow={"visible"}>
          <Row
            width={"100%"}
            gap={["xs", "sm"]}
            alignItems={"center"}
            display={["none !important", "flex !important"]}
            position="relative"
          >
            {tagsToShow.map((item, index) => (
              <Tag
                key={index}
                tag={item.tag}
                id={item.mentionId}
                type={item.mentionType}
              />
            ))}
            {tagsHidden.length > 0 && (
              <Box>
                <Text
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenOtherTags((prev) => !prev)}
                  zIndex={1}
                >
                  {tagsHidden.length > 1
                    ? `+ ${tagsHidden.length} others`
                    : `+ 1 other`}
                </Text>
                {openOtherTags && (
                  <Box
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    position="absolute"
                    // maxHeight={"100px"}
                    width="fit-content"
                    top={"110%"}
                    left={0}
                    zIndex={2}
                    backgroundColor="background"
                    border={`1px solid ${BASE_COLORS.grey}`}
                    borderRadius="md"
                    boxShadow={BASE_COLORS.lightblackShadow}
                    padding="md"
                    gap={10}
                  >
                    {tagsHidden.map((item, index) => (
                      <Tag
                        key={index}
                        tag={item.tag}
                        id={item.mentionId}
                        type={item.mentionType}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Row>
          <Row
            width={"100%"}
            gap={["xs", "sm"]}
            alignItems={"center"}
            overflowX={"scroll"}
            display={["flex !important", "none !important"]}
          >
            {tags?.map((item, index) => (
              <Tag
                key={index}
                tag={item.tag}
                id={item.mentionId}
                type={item.mentionType}
              />
            ))}
          </Row>
        </Box>
      </Column>
      <BouncyModal
        isOpen={isOpen}
        onClose={toggleModal}
        width={["90%", "50%"]}
        height="fit-content"
      >
        {isLoggedin ? renderModalContent() : <Auth />}
      </BouncyModal>
    </Row>
  );
};
export const ImageContainer = styled.div<{
  normalImage: string;
  hoverImage: string;
}>`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.normalImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${PrimaryHoverBox2}:hover & {
    background-image: url(${(props) => props.hoverImage});
  }
`;
