import { BASE_COLORS } from "@/theme";
import { Box, Column } from "../styled";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeltonCardHeader } from "./Components/SkeltonCardHeader";
import { SkeltonCardFooter } from "./Components/SkeltonCardFooter";

type SkeltonLoadingCardProps = {
  cards: number;
};

export const SkeltonLoadingCard = ({ cards }: SkeltonLoadingCardProps) => {
  return (
    <>
      {Array(cards)
        .fill(0)
        .map((item, index) => (
          <Column
            key={index}
            px={["md", "3xl"]}
            py="sm"
            borderBottom={`1px solid ${BASE_COLORS.grey}`}
            borderLeft={`1px solid ${BASE_COLORS.grey}`}
            gap="xl"
          >
            <SkeltonCardHeader />
            <Column gap="lg">
              <Skeleton width={"70%"} height={30} />
              <Skeleton count={8} />
            </Column>
            <SkeltonCardFooter />
          </Column>
        ))}
    </>
  );
};
