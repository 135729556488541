"use client";
import { Box, Button, CenterBox, Text } from "@/components/styled";

import { Form, Formik } from "formik";
import { FormAsyncSelect } from "@/components/FormAsyncSelect";
import { FormInputBox } from "@/components/Form/Components/FormInputBox";
import { FormMentionInput } from "@/components/FormMentionInput/FormMentionInput";
import { universities } from "@/utils/constants";
import { useMemo, useState } from "react";
import { useGetSchools } from "@/hooks/useGetSchools";
import toast from "react-hot-toast";
import { useAddTextPost } from "@/hooks/useAddTextPost";
import * as Yup from "yup";
import { useRouter } from "next/navigation";
import { useUser } from "@/hooks/useUser";
import { LoadingScreen } from "../Loading";
import { useGetPostDetails } from "@/hooks/useGetPostDetails";

const tagSuggestions = ["fraternity", "sororities", "rushlink"];
const validationSchema = Yup.object().shape({
  // universities: Yup.string().required("University is required"),
  nickName: Yup.string().required("Nickname is required"),
  // postTitle: Yup.string().required("Post title is required"),
  post: Yup.string().required("Post content is required"),
});

export const PostComment = ({
  postId,
  toggleModal,
}: // refetch,
{
  postId: string;
  toggleModal: () => void;
  // refetch?: () => void;
}) => {
  const { addTextPost, data: AddTextData, loading, error } = useAddTextPost();
  const [searchTerm, setSearchTerm] = useState("");
  const { data } = useGetSchools(searchTerm);
  const { data: UserData, loading: UserLoading } = useUser();
  const router = useRouter();
  const { refetch } = useGetPostDetails(postId);

  const handleSetSearchTerm = (keyword: string) => {
    setSearchTerm(keyword);
  };

  const extractedSchools = useMemo(() => {
    if (!data?.searchSchools) return [];
    return data?.searchSchools.map((item: any) => ({
      label: item.name,
      value: item.id,
      image: item.logo,
      location: item.location,
    }));
  }, [data?.searchSchools]);

  type FormValues = {
    universities: {
      label: string;
      value: string;
    };
    nickName: string;
    postTitle: string;
    post: string;
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await toast.promise(
        addTextPost({
          variables: {
            createTextPostInput: {
              title: values.postTitle,
              text: values.post,
              schoolId: values.universities.value,
              nickname: values.nickName,
              parentId: postId,
            },
          },
        }),
        {
          loading: "Posting Comment",
          success: "Comment Posted",
          error: "Something Went Wrong",
        }
      );
      if (response.data) {
        toggleModal();
        router.push(`/post/${postId}`);
        refetch();
      }
    } catch (error: any) {
      let errorMessage = "An unexpected error occurred.";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      }
      toast.error(`Error: ${errorMessage}`);
      console.error(error);
      console.log(error);
    }
  };

  if (UserLoading) {
    return <LoadingScreen />;
  }

  return (
    <Formik
      initialValues={{
        universities: {
          label: UserData?.me?.school?.name || "",
          value: UserData?.me?.schoolId || "",
          image: UserData?.me.school?.logo || "",
          location: UserData?.me.school?.location || "",
        },
        nickName: "",
        postTitle: "",
        post: "",
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ values, isValid, dirty }) => (
        <Form>
          <CenterBox
            height="100%"
            alignItems="start"
            gap="2xl"
            mt="2xl"
            px="sm"
            pb="6xl"
          >
            <Text variant="p1_medium" color="primary">
              Post Your Comment Below
            </Text>
            <CenterBox flexDirection={["column", "row"]} width="100%" gap="2xl">
              <Box width={["100%", "50%"]}>
                <FormAsyncSelect
                  options={extractedSchools}
                  name={"universities"}
                  placeholder="Select University"
                  label={"Your University"}
                  handleSetSearchTerm={handleSetSearchTerm}
                  value={values.universities}
                />
              </Box>
              <Box width={["100%", "50%"]}>
                <FormInputBox
                  name={"nickName"}
                  placeholder={"Enter your Nickname"}
                  label={"Your Nickname"}
                  border={true}
                />
              </Box>
            </CenterBox>
            <Box width={["100%", "50%"]}>
              <FormInputBox
                name={"postTitle"}
                placeholder={"Enter post title"}
                label={"Post Title"}
                border={true}
              />
            </Box>
            {/* Mentions */}
            <Box>
              <FormMentionInput
                name={"post"}
                triggerChars={["@", "#"]}
                placeholder="Write your Post. Use @ and # for mentions "
              />
            </Box>
            <Button type="submit" variant="primary" width="150px">
              POST COMMENT
            </Button>
          </CenterBox>
        </Form>
      )}
    </Formik>
  );
};
