import { Box, Row, Column, CenterBox, Text, Button } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import Image from "next/image";
import { useRouter } from "next/navigation";
import onboardingImage from "@/public/assets/images/pngimages/onboarding.png";
export const LoginRegister = () => {
  const router = useRouter();

  const handleSignin = () => {
    router.push("/auth/signin");
  };
  return (
    <CenterBox gap={"2xl"} px={"sm"}  py={"6xl"} >
    <Text fontSize={[20, 27]} textAlign={["center", "start"]}>
      👋 Hey there!
    </Text>
    <Text
      fontSize={[20, 27]}
      textAlign={["center"]}
      width={["90vw", "100%"]}
    >
      Sign in to unlock complete RushLink. experience
    </Text>
    <Box
      backgroundImage={`url(${onboardingImage.src})`}
      backgroundPosition="center"
      backgroundSize="contain"
      height={300}
      width={[330, 450]}
      backgroundRepeat="no-repeat"
    ></Box>
    <Button
      variant="primary"
      px={"xl"}
      py={"md"}
      fontSize={20}
      onClick={handleSignin}
    >
      Access RushLink Account
    </Button>
  </CenterBox>
  );
};
