import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const DELETE_BY_PREMIUM_USER = graphql(`
  mutation DeletePostByPremiumUser($deletePostInput: PostActivityInput!) {
    deletePostByPremiumUser(deletePostInput: $deletePostInput)
  }
`);

export const useDeleteByPremiumUser = () => {
  const [deletePostByPremiumUser, { data, loading, error }] = useMutation(
    DELETE_BY_PREMIUM_USER
  );
  return {
    deletePostByPremiumUser,
    data,
    loading,
    error,
  };
};
