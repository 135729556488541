import { useEffect, useState } from "react";

export const useIsLoggedIn = () => {
  const [isLoggedin, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(Boolean(token));
  }, []);

  return { isLoggedin, setIsLoggedIn };
};
