import styled from "styled-components";
import { BASE_COLORS } from "@/theme";
export const StyledRadioButton = styled.div<{ isSelected: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${BASE_COLORS.primary};
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    width: 15px;
    height: 15px;
    background: #ff575759;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }

  ${(props) =>
    props.isSelected &&
    `
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  `}
`;
