"use client";
import { CenterBox } from "@/components/styled";

import InfiniteLoadingAnimation from "@/public/assets/lottie-animations/infinite-spinner.json";
import { useLottiePlayer } from "@/hooks/useLottiePlayer";

export const InfiniteLoading: React.FC = () => {
  const DynamicLottiePlayer = useLottiePlayer();
  return (
    <CenterBox width={"100%"} height={"100%"}>
      <CenterBox mt="6xl">
        {/* <Image
          src={"/assets/images/svgimages/rushlinkLogo.svg"}
          alt={""}
          height={30}
          width={100}
        /> */}
        <DynamicLottiePlayer
          autoplay
          loop
          style={{
            height: "30%",

            backgroundRepeat: "no-repeat",
          }}
          src={InfiniteLoadingAnimation}
        ></DynamicLottiePlayer>
      </CenterBox>
    </CenterBox>
  );
};
