import { gql, useQuery } from "@apollo/client";

export const GET_POST_DETAILS = gql`
  query FetchPostDetail($input: GetPostDetailInput!) {
    fetchPostDetail(input: $input) {
      authorId
      createdAt
      title
      content
      school {
        logo
        alias
      }
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
        mentionedId
        mentionedId
        mentionType
      }
      type
      nickname
      upvoteCount
      downvoteCount
      replyCount
      viewCount
      bookmarkCount
      id
      status
      replies {
        authorId
        title
        content
        school {
          logo
          alias
        }
        hashtags {
          id
          startIndex
          endIndex
        }
        mentions {
          startIndex
          endIndex
          id
        }
        type
        nickname
        upvoteCount
        downvoteCount
        replyCount
        bookmarkCount
        id
        viewCount
        createdAt
        status
      }
    }
  }
`;

export const useGetPostDetails = (postId: string) => {
  const response = useQuery(GET_POST_DETAILS, {
    variables: {
      input: {
        postId: postId,
      },
    },
  });
  return response;
};
