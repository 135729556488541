import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export const USER_DOCUMENT = graphql(`
  query User {
    me {
      activePremiumPlan {
        name
      }
      schoolId
      flaggedPosts {
        postId
      }
      isPremium
      postActivities {
        type
        postId
        id
      }
      pollVoteActivities {
        id
        pollOptionId
        postId
      }
      phoneNumber
      stripeCustomerId
      id
      school {
        id
        domain
        coverImage
        location
        logo
        name
        alias
        organizationSelectionList {
          value
          label
        }
      }
      email
      countryCode
      blacklisted
      follows {
        followableId
        followableType
        id
      }
      activePremiumPlan {
        id
        name
        showOnApp
        stripePriceId
        createdAt
        price
        validityInDays
        type
      }
      subscriptions {
        allottedDeleteCount
        allottedRestoreCount
        deleteCount
        restoreCount
        startDate
        id
        planId
        stripeSubscriptionId
        status
        plan {
          id
          name
          showOnApp
          stripePriceId
          createdAt
          price
          validityInDays
        }
      }
      deletedUsingPremiumSubscription {
        id
        type
        authorId
        schoolId
        nickname
        title
        parentId
        content
        status
        replyCount
        upvoteCount
        downvoteCount
        bookmarkCount
        createdAt
        updatedAt
        deletedAt
        school {
          id
          domain
          coverImage
          location
          logo
          name
        }
        parentId
        mentions {
          endIndex
          mentionType
          mentionedId
          postId
          schoolIdContext
          startIndex
          id
        }
        hashtags {
          endIndex
          id
          postId
          startIndex
          tag
        }
      }
      organization {
        id
        name
        logo
        type
      }
    }
  }
`);

export const useUserV2 = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const router = useRouter();
  const { data, loading, error, refetch } = useQuery(USER_DOCUMENT, {
    onError: () => {
      // setIsLoggedIn(false);
      // localStorage.removeItem("token");
      // router.push("");
    },
  });

  useEffect(() => {
    if (data?.me) {
      setIsLoggedIn(true);
    } else if (!loading && !error) {
      setIsLoggedIn(false);
    }
  }, [data, loading, error]);

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    if (window) window.location.reload();
    refetch();
  };
  return {
    user: data?.me,
    loading,
    error,
    isLoggedIn,
    logout,
    refetch,
  };
};
