import { Box, Button, Text } from "@/components/styled";
import { useEffect, useState } from "react";
import { BASE_COLORS } from "@/theme";
import { useUser } from "@/hooks/useUser";
import { Spinner } from "@/components/Spinner";
import { LoadingScreen } from "@/components/Loading";
import { InfiniteLoading } from "@/components/InfiniteLoading";
import { useRestorePost } from "@/hooks/useRestorePost";
import toast from "react-hot-toast";
import { getMessageFromError } from "@/utils/getMessageFromError";
import { PostStatus } from "@/gql/graphql";
import { useSearchPosts } from "@/hooks/useSearchPosts";
import { useDeleteByPremiumPlusUser } from "@/hooks/useDeleteByPremiumPlusUser";

export type SuperDeleteForm = {
  id: string;
  toggleModal: () => void;
};
export const SuperDeleteForm = ({ id, toggleModal }: SuperDeleteForm) => {
  const { data, loading } = useUser();
  const { deletePostByPremiumPlusUser, error } = useDeleteByPremiumPlusUser();
  const { refetch } = useSearchPosts({
    keywords: "",
    status: PostStatus.DeletedByPremiumUser,
  });

  const allotedDeleteCountByPremium =
    data?.me?.subscriptions[0]?.allottedDeleteCount ?? 0;
  const deleteCount = data?.me?.subscriptions[0]?.deleteCount ?? 0;
  const [buttonSelected, setButtonSelected] = useState("Cancel");
  const handlePremiumPlusDelete = async () => {
    try {
      await toast.promise(
        deletePostByPremiumPlusUser({
          variables: {
            deletePostInput: {
              postId: id,
            },
          },
        }),
        {
          loading: "Deleting Post",
          success: "Deleted successfully",
          error: "Something went wrong",
        }
      );
      //   if (handleJustDeleted) handleJustDeleted(id);
      toggleModal();
      refetch();
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    } finally {
      toggleModal();
    }
  };

  const handleButtonSelected = (buttonName: string) => {
    setButtonSelected(buttonName);

    buttonName === "Cancel" ? toggleModal() : handlePremiumPlusDelete();
  };
  if (loading) {
    return <InfiniteLoading />;
  }

  return (
    <Box gap="lg" pt={"2xl"}>
      <Text
        size={["40px", "50px"]}
        weight="bold"
        color="primary"
        textAlign="center"
      >
        Super Delete
      </Text>
      <Text size={["14px", "18px"]} color="primary" textAlign="center">
        As a Premium Plus member, you have the exclusive ability to super delete
        posts. Are you sure you want to continue with this action?
      </Text>
      <Text size={["14px", "18px"]} color="primary" textAlign="center">
        You have{" "}
        <Text color="foreground">
          {allotedDeleteCountByPremium - deleteCount}
        </Text>{" "}
        super delete options remaining. Use them wisely!
      </Text>

      <Box
        gap="md"
        justifyContent="center"
        my="7xl"
        flexDirection={["column", "row"]}
        px={["xl", "none"]}
      >
        {["Super Delete", "Cancel"].map((buttonName, index) => (
          <Button
            key={index}
            px={["none", "7xl"]}
            py="md"
            backgroundColor={
              buttonSelected === buttonName ? "primary" : "white"
            }
            border={`1px solid ${BASE_COLORS.primary}`}
            borderRadius="md"
            onClick={() => handleButtonSelected(buttonName)}
          >
            <Text color={buttonSelected === buttonName ? "white" : "primary"}>
              {buttonName}
            </Text>
          </Button>
        ))}
      </Box>
    </Box>
  );
};
