import { graphql } from "@/gql";
import {  useMutation } from "@apollo/client";

export const VOTE_POST = graphql(`
  mutation VotePost($input: VotePostInput!) {
    votePost(input: $input) {
      id
      upvoteCount
      downvoteCount
    }
  }
`);
export const useVotePost = () => {
  const [votePost, { data, loading, error }] = useMutation(VOTE_POST);
  return {
    votePost,
    data,
    loading,
    error,
  };
};
