import { graphql } from "@/gql";
import { PostStatus, PostType } from "@/gql/graphql";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

export const SEARCH_POSTS = graphql(`
  query SearchPosts($input: SearchPostsCriteria!) {
    searchPosts(input: $input) {
      createdAt
      title
      school {
        logo
        alias
      }
      content
      hashtags {
        id
        startIndex
        endIndex
      }
      mentions {
        startIndex
        endIndex
        id
        mentionedId
        mentionType
      }
      type
      nickname
      upvoteCount
      downvoteCount
      viewCount
      replyCount
      bookmarkCount
      id
    }
  }
`);

type SearchOrganizations = {
  keywords?: string;
  skip?: number;
  take?: number;
  type?: PostType;
  status?: PostStatus;
};

export const useSearchPosts = ({
  keywords,
  skip,
  take,
  type,
  status,
}: SearchOrganizations) => {
  const [endReached, setEndReached] = useState(false);
  const { data, loading, error, fetchMore, refetch } = useQuery(SEARCH_POSTS, {
    variables: {
      input: {
        keywords: keywords || "",
        skip: 0,
        take: 10,
        type: type,
        status: PostStatus.Active,
      },
    },
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });
  const loadMore = () => {
    if (endReached === true) return;
    fetchMore({
      variables: {
        input: {
          keywords,
          type,
          status,
          take: 10,
          skip: data?.searchPosts.length || 0,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult?.searchPosts?.length === 0) {
          setEndReached(true);
          return {
            searchPosts: prev.searchPosts,
          };
        }
        if (!fetchMoreResult) return prev;
        return {
          searchPosts: [...prev?.searchPosts, ...fetchMoreResult.searchPosts],
        };
      },
    });
  };
  return {
    data: data?.searchPosts || [],
    loading,
    error,
    loadMore,
    refetch,
  };
};
