import { Box, Row, CenterBox, Text, Column } from "@/components/styled";
import { BASE_COLORS } from "@/theme";

import { RankingPostProps } from "@/utils/types";

import { useUser } from "@/hooks/useUser";
import { useFetchOrganizationbyIds } from "@/hooks/useFetchOrganizationbyIds";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";

import { SkeltonRankingCard } from "@/components/SkeltonRankingCard";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { PostStatus } from "@/gql/graphql";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { logPostToAnalytics } from "@/lib/gtag";
import { CardHeader } from "@/components/Card/Components/CardHeader";
import { StoriesCardHeader } from "../Components/StoriesCardHeader";

export const ShareStoryRanking: React.FC<RankingPostProps> = ({
  schoolLogo,
  postBy,
  universityName,
  tags,
  upVotes,
  downVotes,
  viewCount,
  comments,
  savedPosts,
  type,
  cardComments,
  id,
  createdAt,
  authorId,
  isDeletedFeed,
  isSharedAsStory,
}) => {
  const { data } = useUser();
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [justDeleted, setJustDeleted] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const disableBlur = () => {
    setBlurEnabled(false);
  };
  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const { data: OrganizationData, loading } =
    useFetchOrganizationbyIds(organizationIds);
  const text =
    typeof cardComments === "object" && cardComments !== null
      ? (cardComments as { text?: string }).text || ""
      : "";

  const extractedMentions = tags?.map((item: any) => {
    return {
      tag: text.slice(item.startIndex, item.endIndex),
      mentionId: item.mentionedId,
      mentionType: item.mentionType,
      endIndex: item.endIndex,
      startIndex: item.startIndex,
    };
  });

  const { isLoggedin } = useIsLoggedIn();
  const alreadyUpVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "UPVOTE") return true;
      else return false;
    }
  );
  const alreadyDownVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "DOWNVOTE") return true;
      else return false;
    }
  );
  const alreadyBookMarked = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "BOOKMARK") return true;
      else return false;
    }
  );

  useEffect(() => {
    if (cardComments?.tiers) {
      const allOrganizationIds = cardComments.tiers.flatMap(
        (item) => item.organizationIds
      );
      setOrganizationIds(allOrganizationIds);
    }
  }, [cardComments]);

  const getOrgData = (orgId: string) => {
    if (!OrganizationData?.fetchOrganizationsByIds)
      return { logo: "", name: "", alias: "", id: "" };
    return OrganizationData?.fetchOrganizationsByIds.find(
      (org) => org.id === orgId
    );
  };

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <>
      {loading && !isSharedAsStory ? (
        <SkeltonRankingCard />
      ) : (
        <Box
          width={"90%"}
          borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
          px={isSharedAsStory ? "xl" : ["md", "3xl"]}
          py={["lg", "xl"]}
          position="relative"
          backgroundColor={isSharedAsStory ? "white" : "background"}
          borderRadius={"md"}
        >
          {renderPremiumOverlayIfNeeded()}
          <StoriesCardHeader
            schoolLogo={schoolLogo}
            postBy={postBy}
            universityName={universityName}
            tags={extractedMentions}
            createdAt={createdAt}
            postId={id}
            authorId={authorId}
            handlePostDelete={enableBlur}
            isSharedAsStory={isSharedAsStory}
          />
          <Box py={"md"} gap={"md"} width={"100%"}>
            {cardComments?.tiers &&
              (isSharedAsStory
                ? cardComments.tiers.slice(0, 2)
                : cardComments?.tiers
              ).map((tier, index) => (
                <Box
                  key={index}
                  width={"100%"}
                  alignItems={["center", "start"]}
                  gap={["sm", "md"]}
                  style={{ cursor: "pointer" }}
                  //   onClick={() => router.push(`/post/${id}`)}
                >
                  <Column
                    py={"md"}
                    gap={["md", "md"]}
                    alignItems={"start"}
                    width={"100%"}
                  >
                    <Box width={isSharedAsStory ? "35%" : ["36%", "20%"]}>
                      <CenterBox
                        bg={"primary"}
                        py={["md", "xs"]}
                        borderRadius={"3xl"}
                      >
                        <Text fontSize={16} color={"white"}>
                          {tier.label}
                        </Text>
                      </CenterBox>
                    </Box>
                    <Row width={["100%", "100%"]} overflowX={"scroll"}>
                      <Row alignItems={"center"} gap={["sm", "md"]}>
                        {tier?.organizationIds.map(
                          (org: any, index: number) => (
                            <Row
                              minWidth={"fit-content"}
                              key={index}
                              border={`0.5px solid ${BASE_COLORS.primary}`}
                              borderRadius={"3xl"}
                              alignItems={"center"}
                              px={["sm", "md"]}
                              py={["xs", "xs"]}
                              gap={["xs", "md"]}
                              //   onClick={(e) => {
                              //     e.stopPropagation();
                              //     router.push(
                              //       `/organization/${getOrgData(org)?.alias}/${
                              //         getOrgData(org)?.id
                              //       }/overview`
                              //     );
                              //   }}
                            >
                              <CenterBox
                                border={`1px solid ${BASE_COLORS.primary}`}
                                width={["25px", "30px"]}
                                height={["25px", "30px"]}
                                backgroundImage={`url("${
                                  getOrgData(org)?.logo
                                }")`}
                                backgroundSize={"cover"}
                                backgroundPosition={"center"}
                                borderRadius={"full"}
                              ></CenterBox>
                              <Text fontSize={13} color="primary">
                                {getOrgData(org)?.name}
                              </Text>
                            </Row>
                          )
                        )}
                      </Row>
                    </Row>
                  </Column>
                  <Box
                    display={
                      cardComments?.tiers
                        ? index === cardComments?.tiers.length - 1
                          ? "none !important"
                          : "block"
                        : null
                    }
                    width={"100%"}
                    borderBottom={`0.5px solid ${BASE_COLORS.grey}`}
                  ></Box>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};
