import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";

export const SkeltonCardFooter: React.FC = () => {
  return (
    <CenterBox
      justifyContent="space-between"
      flexDirection="row"
      width={"100%"}
      height={"100%"}
    >
      <CenterBox flexDirection="row" gap="2xl">
        <Skeleton width={35} height={25} />
        <Skeleton width={35} height={25} />
        <Skeleton width={35} height={25} />
      </CenterBox>
      <CenterBox flexDirection="row" gap="2xl">
        <Skeleton width={35} height={25} />
        <Skeleton width={35} height={25} />
      </CenterBox>
    </CenterBox>
  );
};
