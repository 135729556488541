"use client";
import React, { useContext } from "react";
import { Box, Button, CenterBox, Row, StyledInputBox, Text } from "../styled";
import Image from "next/image";
import { useRouter } from "next/navigation";
import onboardingImage from "@/public/assets/images/pngimages/onboarding.png";
import { border } from "styled-system";
import { AppState } from "@/app/context/AppState";
import lightLogo from "@/public/assets/images/svgimages/rushlinkicon_lightmode.svg";

import darkLogo from "@/public/assets/images/svgimages/rushlinkicon_darkmode.svg";
type OnboardingProps = {
  postAction?: () => void;
};
export default function Onboarding({ postAction }: OnboardingProps) {
  const router = useRouter();
  const handleSignin = () => {
    router.push("/auth/signin");
    if (postAction) postAction();
  };
  const { theme } = useContext(AppState);

  const darkTheme = theme === "dark";

  return (
    <CenterBox gap="2xl" py={"xl"}>
      <Box alignItems={"center"}>
        <Image
          src={darkTheme ? darkLogo : lightLogo}
          alt={"logo"}
          width={140}
          height={40}
        />
      </Box>
      <CenterBox gap={"2xl"} px={"sm"}>
        <Text fontSize={[20, 27]} textAlign={["center", "start"]}>
          👋 Hey there!
        </Text>
        <Text
          fontSize={[20, 27]}
          textAlign={["center"]}
          width={["90vw", "100%"]}
        >
          Sign in to unlock complete RushLink. experience
        </Text>
        <Box
          backgroundImage={`url(${onboardingImage.src})`}
          backgroundPosition="center"
          backgroundSize="contain"
          height={300}
          width={[330, 450]}
          backgroundRepeat="no-repeat"
        ></Box>
        <Button
          variant="primary"
          px={"xl"}
          py={"md"}
          fontSize={20}
          onClick={handleSignin}
        >
          Access RushLink Account
        </Button>
      </CenterBox>
    </CenterBox>
  );
}
