import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const DELETE_BY_PREMIUM_PLUS_USER = graphql(`
  mutation DeletePostByPremiumPlusUser($deletePostInput: PostActivityInput!) {
    deletePostByPremiumPlusUser(deletePostInput: $deletePostInput)
  }
`);

export const useDeleteByPremiumPlusUser = () => {
  const [deletePostByPremiumPlusUser, { data, loading, error }] = useMutation(
    DELETE_BY_PREMIUM_PLUS_USER
  );

  return {
    deletePostByPremiumPlusUser,
    data,
    loading,
    error,
  };
};
