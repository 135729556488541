"use client";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Box } from "@/components/styled";
import Image from "next/image";
import { useRouter } from "next/navigation";
import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0% { transform: scale(0); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(1.1);}
  100% { transform: scale(1);}
`;

const FullScreenModalContainer = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
`;

type ModalContentProps = {
  width?: string | string[];
  height?: string | string[];
  borderRadius?: string | number;
};

const ModalContent = styled(Box)<ModalContentProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.background};
  padding-right: 20px;
  padding-left: 20px;
  border-radius: ${(props) => props.borderRadius || "8px"};
  overflow-y: auto;
  z-index: 999;
  border: 0.5px solid white;
`;

type FullScreenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string | string[];
  height?: string | string[];
  borderRadius?: string | number;
};

export const BouncyModal: React.FC<FullScreenModalProps> = ({
  isOpen,
  onClose,
  children,
  width,
  height,
  borderRadius,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted) return null;

  return createPortal(
    <FullScreenModalContainer isOpen={isOpen} onClick={onClose}>
      <ModalContent
        onClick={(e) => e.stopPropagation()}
        width={width || "60%"}
        height={height || "80%"}
        borderRadius={borderRadius}
        overflow={"visible"}
      >
        <Image
          src={"/assets/icons/closeIconPrimary.svg"}
          alt="Close"
          width={40}
          height={40}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        {isOpen && children}
      </ModalContent>
    </FullScreenModalContainer>,
    document.body
  );
};
