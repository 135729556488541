import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const RESTORE_POST = graphql(`
  mutation RestorePostByPremiumPlusUser($restorePostInput: PostActivityInput!) {
    restorePostByPremiumPlusUser(restorePostInput: $restorePostInput)
  }
`);

export const useRestorePost = () => {
  const [restorePostByPremiumPlusUser, { data, loading, error }] =
    useMutation(RESTORE_POST);
  return {
    restorePostByPremiumPlusUser,
    data,
    loading,
    error,
  };
};
