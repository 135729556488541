import { PostType } from "@/gql/graphql";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID || "";

export const pageview = (url: string) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const event = ({
  action,
  type,
  postId,
}: {
  action: string;
  type: string;
  postId: string;
}) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      type: type,
      postId: postId,
    });
  }
};

export const logPostToAnalytics = (postId: string, type: string) => {
  event({
    action: "view_post",
    type: type,
    postId: postId,
  });
};
