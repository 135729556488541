import { gql, useMutation } from "@apollo/client";

export const ADD_TEXT_POST = gql`
  mutation AddTextPost($createTextPostInput: CreateTextPostInput!) {
    addTextPost(createTextPostInput: $createTextPostInput) {
      id
    }
  }
`;
export const useAddTextPost = () => {
  const [addTextPost, { data, loading, error }] = useMutation(ADD_TEXT_POST);
  return {
    addTextPost,
    data,
    loading,
    error,
  };
};
