"use client";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  CenterBox,
  Column,
  HoverBox,
  Row,
  Text,
} from "@/components/styled";

import Image from "next/image";

import { BASE_COLORS } from "@/theme/elements";
import { PollCardProps } from "@/utils/types";
import { useUser } from "@/hooks/useUser";
import { useRouter } from "next/navigation";
import { getTimeUntilExpiry } from "@/utils/getRelativeTime";
import { useVotePoll } from "@/hooks/useVotePoll";
import toast from "react-hot-toast";

import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useModal } from "@/hooks/useModal";
import { BouncyModal } from "@/components/styled/BouncyModal";
import { LoginRegister } from "@/components/LoginRegister/LoginRegister";
import { PostStatus } from "@/gql/graphql";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { logPostToAnalytics } from "@/lib/gtag";
import { CardHeader } from "@/components/Card/Components/CardHeader";
import { StoriesCardHeader } from "../Components/StoriesCardHeader";
import { wrap } from "module";

const calculatePercentage = (voteCount: number, totalVotes: number) => {
  return totalVotes > 0 ? Math.round((voteCount / totalVotes) * 100) : 0;
};

export const ShareStoryPoll: React.FC<PollCardProps> = ({
  schoolLogo,
  tags,
  universityName,
  postBy,
  upVotes,
  downVotes,
  viewCount,
  comments,
  savedPosts,
  id,
  cardComments,
  createdAt,
  authorId,
  status,
  isDeletedFeed,
  type,
  isSharedAsStory,
}) => {
  const { data } = useUser();
  const { votePoll } = useVotePoll();
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [viewPercentage, setViewPercentage] = useState(false);
  const { isLoggedin } = useIsLoggedIn();
  const { toggleModal, isOpen } = useModal();
  const [justDeleted, setJustDeleted] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  const disableBlur = () => {
    setBlurEnabled(false);
  };
  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const [itemVoteCount, setItemVoteCount] = useState(() => {
    const initialVoteCounts = cardComments?.options
      ? cardComments?.options.map((option) => option.voteCount) || []
      : [];
    return initialVoteCounts;
  });
  const [totalVoteCount, setTotalVoteCount] = useState(
    cardComments?.totalVotes
  );
  const handleVotePoll = async (optionIndex: number) => {
    if (isLoggedin) {
      try {
        setViewPercentage(true);
        const { data } = await toast.promise(
          votePoll({
            variables: {
              input: {
                optionIndex: optionIndex,
                postId: id,
              },
            },
          }),
          {
            loading: "Voting",
            success: "Voted",
            error: "Something Went Wrong!",
          }
        );
      } catch (error: any) {
        setViewPercentage(false);
        let errorMessage = "An unexpected error occurred.";
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          errorMessage = error.graphQLErrors[0].message;
        }
        toast.error(`${errorMessage}`);
        console.error(error);
      }
    } else {
      toggleModal();
    }
  };

  const alreadyUpVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "UPVOTE") return true;
      else return false;
    }
  );
  const alreadyDownVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "DOWNVOTE") return true;
      else return false;
    }
  );
  const alreadyBookMarked = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "BOOKMARK") return true;
      else return false;
    }
  );
  const text = (cardComments as { text?: string })?.text || "";
  const extractedMentions = tags?.map((item: any) => {
    return {
      tag: text.slice(item.startIndex, item.endIndex),
      mentionId: item.mentionedId,
      mentionType: item.mentionType,
      endIndex: item.endIndex,
      startIndex: item.startIndex,
    };
  });
  const totalVotes = cardComments?.totalVotes ?? 0;
  const expiryTime = getTimeUntilExpiry(cardComments?.expiryAt);
  const pollExpired = expiryTime === "Expired";
  const alreadyVotedOption = data?.me.pollVoteActivities.find(
    (option) => option.postId === id
  );
  const alreadyVotedOnPoll = Boolean(alreadyVotedOption);

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect(); // Stop observing once the post is in the viewport
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <Box
      ref={postRef}
      width={"90%"}
      //   borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      px={isSharedAsStory ? "xl" : ["md", "3xl"]}
      py={["lg", "xl"]}
      position="relative"
      zIndex={0}
      backgroundColor={isSharedAsStory ? "white" : "background"}
      borderRadius={"md"}
    >
      <BouncyModal isOpen={isOpen} onClose={toggleModal}>
        <LoginRegister />
      </BouncyModal>
      {renderPremiumOverlayIfNeeded()}
      <StoriesCardHeader
        schoolLogo={schoolLogo}
        postBy={postBy}
        universityName={universityName}
        tags={extractedMentions}
        createdAt={createdAt}
        postId={id}
        authorId={authorId}
        handlePostDelete={enableBlur}
        isSharedAsStory={isSharedAsStory}
      />

      <Column
        // onClick={() => router.push(`/post/${id}`)}
        // border={"1px solid red"}
        style={{ cursor: "pointer" }}
      >
        <Box
          width={["100%", "100%"]}
          justifyContent={"center"}
          gap={["xxs", "xs"]}
        >
          <Text
            py={"md"}
            size={[14, 18]}
            weight="bold"
            color={isSharedAsStory ? "black" : "foreground"}
          >
            {cardComments?.text}
          </Text>
          <Box gap={["xs", "sm"]}>
            {!viewPercentage && !alreadyVotedOnPoll && !pollExpired ? (
              <Column
                gap={["sm", "md"]}
                py={"xxs"}
                // flexWrap={isSharedAsStory ? "wrap" : "wrap"}
                // flexWrap={[
                //   isSharedAsStory ? "wrap" : "nowrap",
                //   isSharedAsStory ? "wrap" : "wrap",
                // ]}
              >
                {cardComments?.options?.map((option, index) => (
                  <Box
                    key={index}
                    border={`1px solid ${BASE_COLORS.primary}`}
                    width={"fit-content"}
                    px={["md", "lg"]}
                    py={["xxs", "xs"]}
                    borderRadius={"lg"}
                    onClick={(event) => {
                      handleVotePoll(index);
                      event.stopPropagation();
                      event.preventDefault();
                      setTotalVoteCount((prev) => (prev ? prev + 1 : 1));
                      setItemVoteCount((prev) => {
                        if (!prev) return [];
                        const newVoteCounts = [...prev];
                        newVoteCounts[index] = (newVoteCounts[index] || 0) + 1;
                        return newVoteCounts;
                      });
                    }}
                  >
                    <Text
                      // color="primary"
                      color={isSharedAsStory ? "black" : "foreground"}
                    >
                      {option.text}
                    </Text>
                  </Box>
                ))}
              </Column>
            ) : (
              cardComments?.options?.map((item, index) => (
                <HoverBox
                  borderRadius={"sm"}
                  flexDirection={"row"}
                  key={item.id}
                  justifyContent="space-between"
                  alignItems="center"
                  height={["30px", "35px"]}
                  position={"relative"}
                  px={["sm", "md"]}
                >
                  <Row alignItems={"center"} gap={["sm", "md"]}>
                    <CenterBox
                      position={"relative"}
                      height={"100%"}
                      minWidth={"fit-content"}
                      style={{ zIndex: 2 }}
                    >
                      <Text
                        color={
                          calculatePercentage(
                            itemVoteCount[index],
                            totalVoteCount || 0
                          ) > 30
                            ? "white"
                            : isSharedAsStory
                            ? "black"
                            : "foreground"
                        }
                      >
                        {item.text}
                      </Text>
                    </CenterBox>
                    {alreadyVotedOption?.pollOptionId === item.id ? (
                      <Image
                        style={{ zIndex: 2 }}
                        color="secondary"
                        src={"/assets/icons/voteCheckMarkWhite.svg"}
                        width={15}
                        height={15}
                        alt={"Check"}
                      />
                    ) : null}
                  </Row>
                  <CenterBox
                    height={"100%"}
                    minWidth={"fit-content"}
                    style={{ zIndex: 2 }}
                  >
                    <Text
                      color={
                        calculatePercentage(
                          itemVoteCount[index],
                          totalVoteCount || 0
                        ) > 80
                          ? "white"
                          : isSharedAsStory
                          ? "black"
                          : "foreground"
                      }
                    >
                      {calculatePercentage(
                        itemVoteCount[index],
                        totalVoteCount || 0
                      )}
                      %
                    </Text>
                  </CenterBox>
                  <Box
                    borderRadius={"sm"}
                    bg={"primary"}
                    position={"absolute"}
                    left={"0"}
                    top={"0"}
                    width={
                      totalVoteCount === 0
                        ? ["1.5%", "1.5%"]
                        : `${calculatePercentage(
                            itemVoteCount[index],
                            totalVoteCount || 0
                          )}%`
                    }
                    height={"100%"}
                  ></Box>
                </HoverBox>
              ))
            )}
          </Box>
        </Box>
      </Column>
    </Box>
  );
};
