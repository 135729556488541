import { Box, Row } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { GenericPostType } from "@/utils/types";
import { ShareStoryTextPost } from "../ShareStoryTextPost";
import { ShareStoryPoll } from "../ShareStoryPoll";
import { ShareStoryRanking } from "../ShareStoryRanking";
import { ShareStoryRankingV2 } from "../ShareStoryRankingV2";
import { ShareStorySchoolReview } from "../ShareStorySchoolReview";
import { ShareStoryOrganizationReview } from "../ShareStoryOrganizationReview";

const PostTypeComponents = {
  TEXT: ShareStoryTextPost,
  POLL: ShareStoryPoll,
  RANKING: ShareStoryRanking,
  RANKING_V2: ShareStoryRankingV2,
  SCHOOL_REVIEW: ShareStorySchoolReview,
  ORGANIZATION_REVIEW: ShareStoryOrganizationReview,
};

export const ShareStoryPosts: React.FC<GenericPostType> = ({
  isSharedAsStory,
  type,
  replies,
  replyId,
  isDeletedFeed,
  ...props
}) => {
  const PostComponent =
    PostTypeComponents[type as keyof typeof PostTypeComponents];

  if (!PostComponent) {
    return null;
  }

  return (
    <>
      <Box>
        {/*@ts-ignore */}
        <PostComponent {...props} type={type} isDeletedFeed={isDeletedFeed} />
      </Box>
    </>
  );
};
