import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import { useUser } from "@/hooks/useUser";
import { SchoolReviewPostType } from "@/utils/types";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { CardFooterV2 } from "../Components/CardFooter/CardFooterV2";
import { CardHeader } from "../Components/CardHeader";
import { BASE_COLORS } from "@/theme";
import yellowStar from "@/public/assets/icons/yellowStar.svg";
import greyStar from "@/public/assets/icons/greyStar.svg";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { PostStatus } from "@/gql/graphql";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useEffect, useRef, useState } from "react";
import { logPostToAnalytics } from "@/lib/gtag";
export const SchoolReviewCard: React.FC<SchoolReviewPostType> = ({
  school,
  schoolLogo,
  postBy,
  universityName,
  tags,
  createdAt,
  upVotes,
  downVotes,
  comments,
  savedPosts,
  viewCount,
  id,
  cardComments,
  authorId,
  status,
  isDeletedFeed,
  type,
}) => {
  const { data } = useUser();
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [justDeleted, setJustDeleted] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const disableBlur = () => {
    setBlurEnabled(false);
  };
  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  const alreadyUpVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "UPVOTE") return true;
      else return false;
    }
  );
  const alreadyDownVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "DOWNVOTE") return true;
      else return false;
    }
  );
  const alreadyBookMarked = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "BOOKMARK") return true;
      else return false;
    }
  );
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  const shortTaggedSchool = school?.name
    ? `${school.name.slice(0, 100)}${school.name.length > 100 ? "..." : ""}`
    : "";

  //Google Custom Event In useEffect
  // useEffect(() => {
  //   viewOrganizationReview(id);
  // });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect(); // Stop observing once the post is in the viewport
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <Box
      ref={postRef}
      px={["md", "3xl"]}
      py="sm"
      borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      // borderLeft={`1px solid ${BASE_COLORS.grey}`}
      position="relative"
    >
      {renderPremiumOverlayIfNeeded()}
      <CardHeader
        schoolLogo={schoolLogo}
        postBy={postBy}
        universityName={universityName}
        tags={tags}
        createdAt={createdAt}
        postId={id}
        authorId={authorId}
        handlePostDelete={enableBlur}
        isDeletedFeed={isDeletedFeed}
        handleJustDeleted={handlePostDelete}
      />
      <Box
        py="md"
        color="black"
        onClick={() => router.push(`/post/${id}`)}
        style={{ cursor: "pointer" }}
        gap={"md"}
      >
        {/* <Row
          alignItems={"center"}
          minWidth={"fit-content"}
          border={`1px solid ${BASE_COLORS.primary} `}
          borderRadius="full"
          width="fit-content"
          bg="primaryLightTransparent"
          pr="sm"
        >
          <Box borderRadius="full" overflow="hidden"> 
          <Image src={schoolLogo || ""} alt={"Logo"} width={60} height={40} />
          </Box>
          <Text color="primary">{universityName}</Text>
        </Row> */}
        <Text size={18} weight="bold">
          {cardComments ? cardComments.text : ""}
        </Text>
        <Row gap={"sm"} flexWrap={"wrap"}>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Competition
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.competition ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Hazing
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.hazing ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Housing
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.housing ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Involvement
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.involvement ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Looks
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.looks ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Social Scene
            </Text>
            <Row gap={"xxs"}>
              {stars.map((star) => (
                <Image
                  key={star}
                  width={18}
                  height={18}
                  src={
                    //@ts-ignore
                    star <= cardComments?.socialScene ? yellowStar : greyStar
                  }
                  alt="Star"
                />
              ))}
            </Row>
          </Box>
          <Box
            width={["130px", "150px"]}
            py={"sm"}
            px={"md"}
            gap={"xs"}
            bg={"primary"}
            borderRadius={"md"}
          >
            <Text color="white" fontSize={[14, 16]}>
              Gender Ratio
            </Text>
            <Row justifyContent={"space-between"}>
              <CenterBox>
                <Text color="white">Male</Text>
                <Text color="white">{cardComments?.genderRatio}%</Text>
              </CenterBox>
              <CenterBox>
                <Text color="white">Female</Text>
                <Text color="white">
                  {/*//@ts-ignore */}
                  {100 - (cardComments ? cardComments.genderRatio : 0)}%
                </Text>
              </CenterBox>
            </Row>
          </Box>
        </Row>
      </Box>
      <CardFooterV2
        initialUpVotes={upVotes}
        initialDownVotes={downVotes}
        commentsCount={comments}
        savedPosts={savedPosts}
        postId={id}
        alreadyUpvoted={Boolean(alreadyUpVoted)}
        alreadyDownVoted={Boolean(alreadyDownVoted)}
        alreadyBookMarked={Boolean(alreadyBookMarked)}
        viewCount={viewCount}
      />
    </Box>
  );
};
