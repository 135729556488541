import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const DELETE_POST_BY_AUTHOR = graphql(`
  mutation DeletePostByAuthor($deletePostInput: PostActivityInput!) {
    deletePostByAuthor(deletePostInput: $deletePostInput)
  }
`);

export const useDeletePostByAuthor = () => {
  const [deletePost, { data, loading, error }] = useMutation(
    DELETE_POST_BY_AUTHOR
  );
  return {
    deletePost,
    data,
    loading,
    error,
  };
};
