import { gql, useMutation } from "@apollo/client";

export const UNDO_BOOKMARK = gql`
  mutation UndoBookmarkPost($input: PostActivityInput!) {
    undoBookmarkPost(input: $input) {
      id
    }
  }
`;

export const useUndoBookMark = () => {
  const [undoBookmarkPost, { data, loading, error }] =
    useMutation(UNDO_BOOKMARK);
  return {
    undoBookmarkPost,
    loading,
    error,
    data,
  };
};
