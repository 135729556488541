import { StyledInputBox } from "@/components/styled/StyledInputBox";
import { ChangeEvent } from "react";

export type InputBoxProps = {
  name?: string;
  handleOnChange?: (e: string) => void;
  placeholder: string;
  type?: "text" | "number" | "email" | "password" | "file" | "dropdown" | "tel";
  value: string | number;
  labelColor?: string;
  onBlur?: () => void;
};
export const InputBox: React.FC<InputBoxProps> = ({
  name,
  handleOnChange,
  placeholder,
  type,
  value,
  labelColor,
  onBlur,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (handleOnChange) handleOnChange(e.target.value);
  };

  return (
    <StyledInputBox
      onBlur={onBlur}
      name={name}
      onChange={onChange}
      border={`${type === "file" || "dropdown" ? "none" : "1px solid #E8E8E8"}`}
      borderRadius="sm"
      height={["52px", "52px"]}
      placeholder={placeholder}
      type={type || "text"}
      value={value}
      // color={  ? "white" : "black"}
    />
  );
};
