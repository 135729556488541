import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import { PostStatus } from "@/gql/graphql";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";
import { useUser } from "@/hooks/useUser";
import { BASE_COLORS } from "@/theme";
import Image from "next/image";
import Link from "next/link";

const GRADIENT_STYLE = {
  background:
    "linear-gradient(150deg, rgba(153, 52, 52, 1), rgba(255, 87, 87, 1))",
};

const LINK_STYLE = {
  textDecoration: "none",
};

type CardPremiumOverlayProps = {
  disableBlur: () => void;
};

export const CardPremiumOverlay: React.FC<CardPremiumOverlayProps> = ({
  disableBlur,
}) => {
  const { isLoggedin } = useIsLoggedIn();
  const { data } = useUser();
  return (
    <CenterBox
      width="100%"
      height="100%"
      position="absolute"
      left={0}
      top={0}
      zIndex={10}
      gap="lg"
      style={{
        background: "rgba( 255, 255, 255, 0.10 )",

        backdropFilter: "blur( 3.5px )",
        WebkitBackdropFilter: "blur( 3.5px )",
      }}
    >
      <Text variant="p4_bold" fontWeight="bold" textAlign="center">
        This Post was deleted by RushLink Premium Member
      </Text>

      <CenterBox
        style={GRADIENT_STYLE}
        px={"lg"}
        py="2xl"
        borderRadius="lg"
        gap="lg"
        width="fit-content"
      >
        {isLoggedin && data?.me.activePremiumPlan ? (
          <Text color="white" textAlign="center">
            As you are Premium Member, You can view this post
          </Text>
        ) : (
          <Text color="white" textAlign="center">
            Upgrade To Rushlink Premium To See Deleted Posts
          </Text>
        )}

        {isLoggedin && data?.me.activePremiumPlan ? (
          <CenterBox
            border={`1px solid ${BASE_COLORS.white}`}
            borderRadius="full"
            px="lg"
            py="xs"
            width="110px"
            backgroundColor="primary"
            onClick={() => disableBlur()}
            cursor="pointer"
          >
            <Text color="white">View Post</Text>
          </CenterBox>
        ) : (
          <Link href="/premium" style={LINK_STYLE}>
            <CenterBox
              border={`1px solid ${BASE_COLORS.white}`}
              borderRadius="full"
              px="lg"
              py="xs"
              width="110px"
              backgroundColor="primary"
            >
              <Text color="white">Subscribe</Text>
            </CenterBox>
          </Link>
        )}
      </CenterBox>
    </CenterBox>
  );
};
