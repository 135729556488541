import React from "react";
import { InputBox, InputBoxProps } from "@/components/Profile/InputBox";
import { Column } from "@/components/styled/Column";
import { Box, Text } from "@/components/styled";
import { useField } from "formik";
import { BASE_COLORS } from "@/theme";

export type FormInputBoxProps = Omit<InputBoxProps, "value"> & {
  label?: string;
  labelColor?: string;
  shadow?: boolean;
  border?: boolean;
  name: string;

  limitInput?: (value: string) => string;
};

export const FormInputBox: React.FC<FormInputBoxProps> = ({
  label,
  labelColor,
  name,
  shadow = false,
  border,
  limitInput,

  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const onChange = (newValue: string) => {
    if (limitInput) {
      newValue = limitInput(newValue);
    }
    helpers.setValue(newValue);
  };

  const onBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <Column gap="8px" width="100%" position="relative">
      {label ? (
        <Text size={["14px", "16px"]} weight="bold" color="foreground">
          {label}
        </Text>
      ) : null}
      <Box
        boxShadow={shadow ? BASE_COLORS.blackShadow : "none"}
        borderBottom={border ? `1px solid ${BASE_COLORS.grey}` : null}
        borderRadius={shadow ? "md" : "none"}
        overflow="hidden"
      >
        <InputBox
          handleOnChange={onChange}
          name={name}
          value={field.value}
          labelColor={labelColor}
          onBlur={onBlur}
          {...rest}
        />
      </Box>
      {meta.touched && meta.error && (
        <Box position={"absolute"} top={100}>
          <Text style={{ color: "red" }}>{meta.error}</Text>
        </Box>
      )}
    </Column>
  );
};
