import React, { useState, useEffect } from "react";
import {
  Mention,
  MentionSearchEvent,
  MentionItemTemplateOptions,
} from "primereact/mention";

// import "primereact/resources/primereact.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";

import { Text } from "../Text";
import { Box, CenterBox, PrimaryHoverBox } from "../Box";
import { Column } from "../Column";
import Image from "next/image";
import { useDeviceDetection } from "@/hooks/deviceDetection";
import RushlinkImage from "@/public/assets/images/pngimages/rushlink.png";
import { useTheme } from "styled-components";

interface SuggestionItem {
  name: string;
  logo: string;
  id: string;
  alias: string;
}

interface MentionInputProps {
  customerSuggestions: SuggestionItem[];
  tagSuggestions: string[];
  onChange: (value: string) => void;
  onMentionSearch: (query: string, trigger: string) => void;
  triggerChars: string[];
  placeholder?: string;
  loading: boolean;
}

export const StyledMentionInput: React.FC<MentionInputProps> = ({
  customerSuggestions,
  tagSuggestions,
  onChange,
  onMentionSearch,
  triggerChars,
  placeholder,
  loading,
}) => {
  const [value, setValue] = useState<string>("");
  const [customers, setCustomers] = useState<SuggestionItem[]>([]);
  const [multipleSuggestions, setMultipleSuggestions] = useState<any>([]);
  const isMobile = useDeviceDetection();
  const theme = useTheme();

  useEffect(() => {
    const updatedCustomers = customerSuggestions.map((d) => ({
      ...d,
      nickname: d.alias,
    }));
    setCustomers(updatedCustomers);
  }, [customerSuggestions]);

  const onMultipleSearch = (event: MentionSearchEvent) => {
    const trigger = event.trigger;
    const query = event.query;

    if (trigger === "@") {
      onMentionSearch(query, trigger);
    }

    const suggestions = !query.trim().length
      ? trigger === "@"
        ? [...customers]
        : [...tagSuggestions]
      : trigger === "@"
      ? customers.filter(
          (customer) =>
            customer.name.toLowerCase().startsWith(query.toLowerCase()) ||
            customer.alias.toLowerCase().startsWith(query.toLowerCase())
        )
      : tagSuggestions.filter((tag) =>
          tag.toLowerCase().startsWith(query.toLowerCase())
        );

    setMultipleSuggestions(suggestions);
  };

  const itemTemplate = (suggestion: SuggestionItem) => {
    const uniImage = suggestion.logo || RushlinkImage;
    return (
      <>
        {loading ? (
          <Text>Loading</Text>
        ) : (
          <CenterBox
            flexDirection="row"
            justifyContent="start"
            className="flex align-items-center"
            width="100%"
            gap="sm"
            // hoverBackgroundColor=""
          >
            <Box borderRadius="full" overflow="hidden">
              <Image
                alt={suggestion.name}
                src={uniImage}
                width={35}
                height={35}
              />
            </Box>
            <Column>
              <Text size={13}>{suggestion.name}</Text>
              <Text color="primary" size={10}>
                @{suggestion.alias}
              </Text>
            </Column>
          </CenterBox>
        )}
      </>
    );
  };

  const multipleItemTemplate = (
    suggestion: any,
    options: MentionItemTemplateOptions
  ) => {
    const trigger = options.trigger;

    if (trigger === "@" && suggestion.nickname) {
      return itemTemplate(suggestion);
    } else if (trigger === "#" && !suggestion.nickname) {
      return <Text>{suggestion}</Text>;
    }

    return null;
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <CenterBox className="card" alignItems="start">
      <Mention
        value={value}
        onChange={(e) =>
          handleChange(e as unknown as React.ChangeEvent<HTMLTextAreaElement>)
        }
        inputStyle={{
          backgroundColor: theme.colors.background,
          color: theme.colors.foreground,
        }}
        trigger={triggerChars}
        suggestions={multipleSuggestions}
        onSearch={onMultipleSearch}
        field={["nickname"]}
        placeholder={loading ? "Loading..." : placeholder}
        itemTemplate={multipleItemTemplate}
        rows={isMobile ? 10 : 9}
        cols={isMobile ? 45 : 75}
        panelStyle={{ backgroundColor: theme.colors.background }}
      />
    </CenterBox>
  );
};
