export const getTimeUntilExpiry = (expiryDate?: Date): string => {
  if (!expiryDate) return "No expiry date";
  const expiryTime = new Date(expiryDate).getTime();
  const currentTime = new Date().getTime();
  const timeDifference = expiryTime - currentTime;

  if (timeDifference <= 0) return "Expired";

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours < 1) {
    return `${minutes} minute left`;
  } else {
    return `${hours} hour and ${minutes} minutes left`;
  }
};
