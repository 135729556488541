import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";

export const SkeltonRankingCard: React.FC = () => {
  return (
    <Box width={"100%"} height={"100%"} px="lg">
      <CenterBox
        justifyContent="start"
        flexDirection="row"
        height="fit-content"
        gap="lg"
      >
        <Skeleton height={50} width={50} borderRadius={200} />
        <Column gap="lg">
          <Row gap="sm">
            <Skeleton width={70} height={25} borderRadius={200} />
            <Skeleton width={5} height={5} borderRadius={200} />
            <Skeleton width={70} height={25} />
            <Skeleton width={70} height={25} />
          </Row>
        </Column>
      </CenterBox>
      <Column gap="lg" pl="2xl" mt="2xl">
        <CenterBox flexDirection="row" justifyContent="start" gap="lg">
          <Skeleton width={90} height={30} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
        </CenterBox>
        <CenterBox flexDirection="row" justifyContent="start" gap="lg">
          <Skeleton width={90} height={30} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
        </CenterBox>
        <CenterBox flexDirection="row" justifyContent="start" gap="lg">
          <Skeleton width={90} height={30} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
        </CenterBox>
        <CenterBox flexDirection="row" justifyContent="start" gap="lg">
          <Skeleton width={90} height={30} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
          <Skeleton width={200} height={45} borderRadius={200} />
        </CenterBox>
      </Column>
    </Box>
  );
};
