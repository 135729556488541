import React, { useState, useCallback } from "react";
import { useField } from "formik";
import { StyledMentionInput } from "../styled/StyledMentionInput";
import { useGetMentionSuggestion } from "@/hooks/useGetMentionSuggestions";
import { Box } from "../styled";
import { useUserV2 } from "@/hooks/useUserV2";

interface SuggestionItem {
  name: string;
  logo: string;
  id: string;
  alias: string;
}

interface FormikMentionInputProps {
  name: string;
  triggerChars: string[];
  placeholder?: string;
}

const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const FormMentionInput: React.FC<FormikMentionInputProps> = ({
  name,
  triggerChars,
  placeholder,
}) => {
  const tagSuggestions = ["fraternity", "sororities", "rushlink"];
  const [field, meta, helpers] = useField(name);
  const [mentionQuery, setMentionQuery] = useState<string>("");
  const { user } = useUserV2();

  const { data, loading } = useGetMentionSuggestion(
    mentionQuery,
    user?.schoolId || ""
  );

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  const debouncedMentionSearch = useCallback(
    debounce((query: string, trigger: string) => {
      if (trigger === "@") {
        setMentionQuery(query);
      }
    }, 300),
    []
  );

  const handleMentionSearch = (query: string, trigger: string) => {
    debouncedMentionSearch(query, trigger);
  };

  return (
    <Box>
      <StyledMentionInput
        tagSuggestions={tagSuggestions}
        {...field}
        customerSuggestions={data?.getMentionSuggestions || []}
        onChange={handleChange}
        onMentionSearch={handleMentionSearch}
        triggerChars={triggerChars}
        placeholder={placeholder}
        loading={loading}
      />
    </Box>
  );
};
