"use client";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { useUser } from "@/hooks/useUser";
import { Box, CenterBox, Row, Text } from "../../styled";
import { BASE_COLORS } from "@/theme";
import { GenericPostType } from "@/utils/types";
import styled from "styled-components";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { PostStatus } from "@/gql/graphql";
import { logPostToAnalytics } from "@/lib/gtag";
import { DeletedByAdminOverlay } from "@/components/Card/Components/DeletedByAdminOverlay";
import { StoriesCardHeader } from "../Components/StoriesCardHeader";

const StyledLink = styled(Link)`
  color: ${BASE_COLORS.primary};
  text-decoration: none;
  cursor: pointer;
`;

type EntityItem = {
  mentionedId: string;
  [key: string]: string;
  mentionType: string;
};

export const ShareStoryTextPost: React.FC<GenericPostType> = ({
  schoolLogo,
  tags = [],
  cardComments,
  universityName,
  hashtags = [],
  postBy,
  title,
  id,
  createdAt,
  isActiveReply,
  authorId,
  status,
  isDeletedFeed,
  isReply,
  type,
  isSharedAsStory,
}) => {
  const { data } = useUser();
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const [justDeleted, setJustDeleted] = useState(false);

  const disableBlur = () => {
    setBlurEnabled(false);
  };

  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  const text = (cardComments as { text?: string })?.text || "";
  const formattedCardComments = isSharedAsStory ? text.slice(0, 405) : text;

  // Ensure tags and hashtags are arrays
  const safeTags = Array.isArray(tags) ? tags : [];
  const safeHashtags = Array.isArray(hashtags) ? hashtags : [];

  const mergedArray = [...safeTags, ...safeHashtags].sort(
    (a, b) => a.startIndex - b.startIndex
  );

  const extractedMentions = safeTags.map((item: any) => {
    return {
      tag: text.slice(item.startIndex, item.endIndex),
      mentionId: item.mentionedId,
      mentionType: item.mentionType,
      endIndex: item.endIndex,
      startIndex: item.startIndex,
    };
  });

  const renderContent = () => {
    let lastIndex = 0;
    const result = [];

    mergedArray.forEach((item, index) => {
      if (item.startIndex > lastIndex) {
        result.push(text.slice(lastIndex, item.startIndex));
      }

      const mention = text.slice(item.startIndex, item.endIndex);

      const isHashtag = safeHashtags.includes(item);
      const mentionLink =
        !isHashtag && (item as unknown as EntityItem).mentionType === "SCHOOL"
          ? `/school/${universityName}/overview`
          : `/organization/${universityName}/${
              (item as unknown as EntityItem).mentionedId
            }/overview`;

      const linkHref = isHashtag
        ? `/search?tab=posts&query=${mention.slice(1)}`
        : mentionLink;

      result.push(
        <StyledLink
          key={index}
          href={linkHref}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {mention}
        </StyledLink>
      );
      lastIndex = item.endIndex;
    });

    if (lastIndex < formattedCardComments.length) {
      result.push(formattedCardComments.slice(lastIndex));
    }

    return result;
  };
  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else if (status === PostStatus.DeletedByAdmin) {
      return <DeletedByAdminOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }
    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id, title]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <Box
      ref={postRef}
      px={isSharedAsStory ? "xl" : ["md", "3xl"]}
      py={isSharedAsStory ? "md" : "none"}
      //   pr={isReply ? "3xl" : ["md", "3xl"]}
      //   py={isReply ? "xs" : "lg"}
      //   borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      //   bg={isActiveReply ? "primaryTransparentLight" : "transparent"}
      // position="relative"

      width={"90%"}
      my={"sm"}
      backgroundColor={isSharedAsStory ? "white" : "background"}
      borderRadius={"md"}
    >
      {renderPremiumOverlayIfNeeded()}
      <StoriesCardHeader
        schoolLogo={schoolLogo}
        postBy={postBy}
        universityName={universityName}
        tags={extractedMentions}
        createdAt={createdAt}
        postId={id}
        authorId={authorId}
        handlePostDelete={enableBlur}
        isSharedAsStory={isSharedAsStory}
      />
      <Row
        py="md"
        color="black"

        // onClick={() => router.push(`/post/${id}`)}
        // style={{ cursor: "pointer" }}
      >
        <Box gap="sm">
          <Text
            size={18}
            weight="bold"
            color={isSharedAsStory ? "black" : "foreground"}
          >
            {title}
          </Text>
          <Text color={isSharedAsStory ? "black" : "foreground"}>
            {renderContent()}
          </Text>
        </Box>
      </Row>
    </Box>
  );
};
