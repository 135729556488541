import { gql, useMutation } from "@apollo/client";

export const BOOKMARK_POST = gql`
  mutation BookmarkPost($input: PostActivityInput!) {
    bookmarkPost(input: $input) {
      id
    }
  }
`;

export const useBookMarkPost = () => {
  const [bookmarkPost, { data, loading, error }] = useMutation(BOOKMARK_POST);
  return { bookmarkPost, data, loading, error };
};
