import { graphql } from "@/gql";
import {  useMutation } from "@apollo/client";

export const UNDO_VOTE_POST = graphql(`
  mutation UndoVotePost($input: VotePostInput!) {
    undoVotePost(input: $input) {
      id
      upvoteCount
      downvoteCount
    }
  }
`);

export const useUndoVotePost = () => {
  const [undoVotePost, { data, loading, error }] = useMutation(UNDO_VOTE_POST);
  return {
    undoVotePost,
    loading,
    error,
    data,
  };
};
