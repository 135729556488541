"use client";
import React, { useState } from "react";
import { Box, Button, Row, Text } from "@/components/styled";
import { FLAG_POST_OPTIONS, Issues } from "@/utils/constants";
import { Formik, Form } from "formik";
import { BASE_COLORS } from "@/theme";
import styled from "styled-components";
import { StyledRadioButton } from "./Components/StyledRadioButton";
import toast from "react-hot-toast";
import { useFlagPost } from "@/hooks/useFlagPost";
import { PostFlagType } from "@/gql/graphql";
import { getMessageFromError } from "@/utils/getMessageFromError";
import { useUserV2 } from "@/hooks/useUserV2";

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

type ReportPostFormProps = {
  id: string;
  toggleModal: () => void;
};

type FormValues = {
  picked: PostFlagType;
};

export const ReportPostForm = ({ id, toggleModal }: ReportPostFormProps) => {
  const [selectedIssue, setSelectedIssue] = useState(
    PostFlagType.InappropriateContent
  );
  const [buttonSelected, setButtonSelected] = useState("Submit");
  const { flagPost, loading, error } = useFlagPost();
  const { refetch } = useUserV2();
  const handleSubmit = async (values: FormValues) => {
    setButtonSelected("Submitting");
    try {
      const result = await toast.promise(
        flagPost({
          variables: {
            input: {
              postId: id,
              type: values.picked,
            },
          },
        }),
        {
          loading: "Reporting Post",
          success:
            "Reported successfully! We will look into it and take necessary actions. ",
          error: `Something went wrong`,
        }
      );
      toggleModal();
      refetch();
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    }
  };

  const HandleButtonSelected = (button: string) => {
    setButtonSelected(button);
    if (button === "Cancel") {
      toggleModal();
    }
  };
  return (
    <Box py={"xl"} gap="lg">
      <Text
        color="primary"
        weight="bold"
        size={["20px", "25px"]}
        px={["lg", "4xl"]}
        width={"80vw"}
      >
        What type of issue are you reporting?
      </Text>
      <Text size={["14px", "18px"]} px={["lg", "4xl"]} pb={["xl", "4xl"]}>
        Choose one of the valid reasons for reporting this post
      </Text>
      <Formik
        initialValues={{
          picked: PostFlagType.InappropriateContent,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <Box
              flexWrap={["nowrap", "wrap"]}
              height={["30vh", "250px"]}
              gap={"md"}
              pl={["lg", "4xl"]}
              overflow={["scroll", "hidden"]}
            >
              {FLAG_POST_OPTIONS.map((issue, index) => (
                <StyledLabel
                  key={index}
                  onClick={() => {
                    setSelectedIssue(issue.value);
                    setFieldValue("picked", issue.value);
                  }}
                >
                  <StyledRadioButton
                    isSelected={selectedIssue === issue.value}
                  />
                  <Text size={["14px", "18px"]}>{issue.label}</Text>
                </StyledLabel>
              ))}
            </Box>
            <Row gap={"md"} justifyContent={"flex-end"} px={"xl"}>
              {["Submit", "Cancel"].map((button, index) => (
                <Button
                  key={index}
                  type={buttonSelected === "Cancel" ? "button" : "submit"}
                  backgroundColor={
                    buttonSelected === button ? "primary" : "white"
                  }
                  border={`1px solid ${BASE_COLORS.primary}`}
                  borderRadius={"full"}
                  py={["xs", "sm"]}
                  px={["2xl", "4xl"]}
                  onClick={() => HandleButtonSelected(button)}
                >
                  <Text color={buttonSelected === button ? "white" : "primary"}>
                    {button}
                  </Text>
                </Button>
              ))}
            </Row>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
