import { gql, useQuery } from "@apollo/client";

export const GET_MENTION_SUGGESTIONS = gql`
  query GetMentionSuggestions($input: MentionSuggestionInput!) {
    getMentionSuggestions(input: $input) {
      name
      logo
      id
      alias
    }
  }
`;

export const useGetMentionSuggestion = (keywords: string, schoolId: string) => {
  const response = useQuery(GET_MENTION_SUGGESTIONS, {
    variables: {
      input: {
        keywords,
        schoolId,
      },
    },
  });
  return response;
};
