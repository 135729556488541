import { BASE_COLORS } from "@/theme";
import { Box, PrimaryHoverBox2 } from "../styled";
import { BASE_TEXT_VARIANTS } from "@/theme";
import Link from "next/link";

type TagProps = {
  tag: string;
  id: string;
  type: string;
  // schoolAlias: string;
};

export const Tag = ({ tag, type, id }: TagProps) => {
  const RouteLink =
    type === "SCHOOL"
      ? `/school/${tag.slice(1)}/overview`
      : `/organization/${tag.slice(1)}/${id}/overview`;
  return (
    <>
      <Link href={RouteLink} style={{ textDecoration: "none" }}>
        <PrimaryHoverBox2
          minWidth={"fit-content"}
          maxHeight={"fit-content"}
          bg={"primaryLight"}
          border={`1px solid ${BASE_COLORS.primary}`}
          borderRadius="xl"
          fontWeight="regular"
          fontSize={[12, 14]}
          fontFamily={BASE_TEXT_VARIANTS.p5.fontFamily}
          color="primary"
          px={["xs", "md"]}
          py={["xxs", "xs"]}
        >
          {tag}
        </PrimaryHoverBox2>
      </Link>
    </>
  );
};
