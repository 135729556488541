import { Box, Button, Row, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { SetStateAction, useEffect, useState } from "react";
import { useDeletePostByAuthor } from "@/hooks/useDeletePostByAuthor";
import toast from "react-hot-toast";
import { useUser } from "@/hooks/useUser";

import { Spinner } from "@/components/Spinner";
import { getMessageFromError } from "@/utils/getMessageFromError";
import { useDeleteByPremiumUser } from "@/hooks/useDeleteByPremiumUser";
import { useDeleteByPremiumPlusUser } from "@/hooks/useDeleteByPremiumPlusUser";
import { InfiniteLoading } from "@/components/InfiniteLoading";
import { useRouter } from "next/navigation";
import { PremiumPlanType } from "@/gql/graphql";
import { border, borderRadius } from "styled-system";
import Link from "next/link";
export type DeletePostFormProps = {
  id: string;
  toggleModal: () => void;
  authorId: string;
  handlePostDelete: () => void;
  handleJustDeleted: ((PostId: string) => void) | undefined;
};

export const DeletePostForm = ({
  id,
  toggleModal,
  authorId,
  handlePostDelete,
  handleJustDeleted,
}: DeletePostFormProps) => {
  const [buttonSelected, setButtonSelected] = useState("Delete");
  const { deletePost } = useDeletePostByAuthor();
  const { data: user, loading } = useUser();
  const [LoggedInuser, setLoggedInUser] = useState<SetStateAction<string>>();
  const { deletePostByPremiumUser, error } = useDeleteByPremiumUser();
  const allotedDeleteCountByPremium =
    user?.me?.subscriptions[0]?.allottedDeleteCount ?? 0;
  const deleteCount = user?.me?.subscriptions[0]?.deleteCount ?? 0;
  const { deletePostByPremiumPlusUser, error: PremiumPlusUserError } =
    useDeleteByPremiumPlusUser();

  const router = useRouter();
  const delete_count = allotedDeleteCountByPremium - deleteCount;
  useEffect(() => {
    if (user?.me.id === authorId) {
      setLoggedInUser("Author");
    } else if (
      user?.me.id === authorId &&
      user?.me.activePremiumPlan?.type === PremiumPlanType.RushlinkPremium
    ) {
      setLoggedInUser("Author");
    } else if (
      user?.me.id === authorId &&
      user?.me.activePremiumPlan?.type === PremiumPlanType.RushlinkPremiumPlus
    ) {
      setLoggedInUser("Author");
    } else if (
      user?.me.id !== authorId &&
      user?.me.activePremiumPlan?.type === PremiumPlanType.RushlinkPremium
    ) {
      setLoggedInUser("Premium");
    } else if (
      user?.me.id !== authorId &&
      user?.me.activePremiumPlan?.type === PremiumPlanType.RushlinkPremiumPlus
    ) {
      setLoggedInUser("Premium-Plus");
    } else if (
      (user?.me.id !== authorId &&
        user?.me.activePremiumPlan?.type !== PremiumPlanType.RushlinkPremium) ||
      "Premium"
    ) {
      setLoggedInUser("NotPremiumOrAuthor");
    }
  }, [user]);

  const handleAuthorDelete = async () => {
    try {
      await toast.promise(
        deletePost({
          variables: {
            deletePostInput: {
              postId: id,
            },
          },
        }),
        {
          loading: "Deleting Post",
          success: "Deleted your post successfully",
          error: "Something went wrong",
        }
      );
      if (handleJustDeleted) handleJustDeleted(id);
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    } finally {
      toggleModal();
    }
  };

  const handlePremiumDelete = async () => {
    console.log("PremiumDelete");
    try {
      await toast.promise(
        deletePostByPremiumUser({
          variables: {
            deletePostInput: {
              postId: id,
            },
          },
        }),
        {
          loading: "Deleting Post",
          success: "Deleted Post successfully",
          error: "Something went wrong",
        }
      );
      handlePostDelete();
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    } finally {
      toggleModal();
    }
  };
  const handlePremiumPlusDelete = async () => {
    try {
      await toast.promise(
        deletePostByPremiumPlusUser({
          variables: {
            deletePostInput: {
              postId: id,
            },
          },
        }),
        {
          loading: "Deleting Post",
          success: "Deleted successfully",
          error: "Something went wrong",
        }
      );
      if (handleJustDeleted) handleJustDeleted(id);
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    } finally {
      toggleModal();
    }
  };

  const handlePushToPremium = () => {
    router.push("/premium");
  };

  const handleNothing = () => {
    return null;
  };

  let functionToRun: () => void;
  switch (LoggedInuser) {
    case "Author":
      functionToRun = handleAuthorDelete;
      break;
    case "Premium":
      functionToRun = handlePremiumDelete;
      break;
    case "Premium-Plus":
      functionToRun = handlePremiumPlusDelete;
      break;
    case "NotPremiumOrAuthor":
      functionToRun = handlePushToPremium;
      break;
    default:
      functionToRun = handleNothing;
      break;
  }

  const handleButtonSelected = (buttonName: string) => {
    setButtonSelected(buttonName);

    buttonName === "Cancel" ? toggleModal() : functionToRun();
  };

  if (loading) {
    return <InfiniteLoading />;
  }
  return (
    <Box
      gap="lg"
      pt="6xl"
      backgroundImage={`url(${"/assets/images/svgimages/deletePostBgImage.svg"})`}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
    >
      <Text
        size={["40px", "50px"]}
        weight="bold"
        color="primary"
        textAlign="center"
      >
        Delete Post?
      </Text>
      {user?.me.isPremium ? (
        user?.me.id === authorId ? (
          <Text size={["14px", "18px"]} color="grey" textAlign="center">
            Deleted post will be removed from the platform. Are you sure you
            want to proceed ?
          </Text>
        ) : (
          <Text size={["14px", "18px"]} color="grey" textAlign="center">
            You are deleting someone else's post, it'll be removed from the
            rushlink platform. Are you sure you want to delete this post?
            <br />
            <Text size={["14px", "18px"]} color="grey" textAlign="center">
              You have <Text color="primary">{delete_count}</Text> delete
              options left.{" "}
              {delete_count > 0 && (
                <Text color="primary">Use them wisely!</Text>
              )}
            </Text>
          </Text>
        )
      ) : user?.me.id === authorId ? (
        <Text size={["14px", "18px"]} color="grey" textAlign="center">
          Deleted post will be removed from the platform. Are you sure you want
          to proceed?
        </Text>
      ) : (
        <Text size={["14px", "18px"]} color="grey" textAlign="center">
          You are attempting to delete someone else's post. To perform this
          action, you need to have a premium membership. Check out our premium
          plans by clicking the button below:
        </Text>
      )}
      {delete_count > 0 || LoggedInuser === "Author" ? (
        <Box
          gap="md"
          justifyContent="center"
          my="7xl"
          flexDirection={["column", "row"]}
          px={["xl", "none"]}
        >
          {["Delete", "Cancel"].map((buttonName, index) => (
            <Button
              key={index}
              px={["none", "7xl"]}
              py="md"
              backgroundColor={
                buttonSelected === buttonName ? "primary" : "white"
              }
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius="md"
              onClick={() => handleButtonSelected(buttonName)}
            >
              <Text color={buttonSelected === buttonName ? "white" : "primary"}>
                {buttonName}
              </Text>
            </Button>
          ))}
        </Box>
      ) : (
        <Link href={"/premium"} style={{ textDecoration: "none" }}>
          <Box my="7xl" alignItems={"center"}>
            <Button
              py={10}
              px={25}
              backgroundColor={BASE_COLORS.primary}
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius={"5px"}
              width={"25vw"}
            >
              <Text color="white">Go to Premium</Text>
            </Button>
          </Box>
        </Link>
      )}
    </Box>
  );
};
