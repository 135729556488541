import { Box, Text, Row, PrimaryHoverBox } from "@/components/styled";
import Image from "next/image";
import { useGetPostDetails } from "@/hooks/useGetPostDetails";
import { useEffect, useState } from "react";
import { ShareStory } from "@/components/Stories/ShareStory";
import toast from "react-hot-toast";
import { useSharePostAsStory } from "@/hooks/useSharePostAsStory";
import { LoadingScreen } from "@/components/Loading";
import { useRouter } from "next/navigation";
import { MyStories } from "@/components/Stories/Components/MyStories";

export const ShareWithDetails = [
  {
    logo: "/assets/images/svgimages/sharefbLogo.svg",
    name: "Facebook",
    getShareUrl: (extractedPostSlug: string) =>
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/post/${extractedPostSlug}`,
  },
  {
    logo: "/assets/images/svgimages/shareTwitterLogo.svg",
    name: "Twitter",
    getShareUrl: (extractedPostSlug: string) =>
      `https://twitter.com/intent/tweet?&url=${window.location.origin}/post/${extractedPostSlug}`,
  },
  {
    logo: "/assets/images/svgimages/shareLinkedinLogo.svg",
    name: "Linkedin",
    getShareUrl: (extractedPostSlug: string) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.origin}/post/${extractedPostSlug}`,
  },

  {
    logo: "/assets/images/svgimages/shareCopyLinkLogo.svg",
    name: "Copy Link",
    getShareUrl: (extractedPostSlug: string) =>
      `${window.location.origin}/post/${extractedPostSlug}`,
  },
];

export const ShareModal = ({
  postId,
  onClose,
}: {
  postId: string;
  onClose: () => void;
}) => {
  const { data: postDetails } = useGetPostDetails(postId);
  const [copiedLink, setCopiedLink] = useState(false);
  const [sharePost, { loading, error }] = useSharePostAsStory();
  const extractedPost = postDetails?.fetchPostDetail;
  const [isShared, setIsShared] = useState(false);

  const extractedPostSlug = postId;

  const handleShare = (platform: any) => {
    if (platform.name === "Copy Link") {
      navigator.clipboard
        .writeText(platform.getShareUrl(extractedPostSlug))
        .then(() => {
          setCopiedLink(true);
          setTimeout(() => setCopiedLink(false), 2000);
        })
        .catch((err) => console.error("Failed to copy link: ", err));
    } else {
      window.open(platform.getShareUrl(extractedPostSlug), "_blank");
    }
  };
  const shareStory = async () => {
    onClose();
    try {
      const result = await toast.promise(
        sharePost({
          variables: {
            input: {
              postId: postId,
            },
          },
        }),
        {
          loading: "Sharing your post as a story",
          success: "Successfully shared your post as a story",
          error: (err) => {
            return err instanceof Error
              ? err.message
              : "Failed to share post as story";
          },
        }
      );
    } finally {
      setIsShared(true);

      if (loading) {
        <LoadingScreen />;
      }
    }
  };
  return (
    <Box>
      <Text
        color="primary"
        textAlign={"center"}
        size={["16px", "25px"]}
        weight="bold"
        pt={"xl"}
        pb="2xl"
      >
        Share Post
      </Text>
      <Box height={["68vw", "15vw"]} overflowY={"scroll"}>
        <ShareStory postId={postId} />
      </Box>
      <Row justifyContent={"space-around"} mt={"xl"}>
        {ShareWithDetails.map((detail, index) => (
          <PrimaryHoverBox
            key={index}
            onClick={() => handleShare(detail)}
            style={{ cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
            px={"sm"}
            py={"sm"}
            borderRadius={"sm"}
          >
            <Image
              src={detail?.logo ?? ""}
              alt={"logo"}
              width={35}
              height={35}
            />
            <Text size={["12px", "14px"]} pt={"md"}>
              {detail?.name === "Copy Link" && copiedLink
                ? "Copied!"
                : detail?.name}
            </Text>
          </PrimaryHoverBox>
        ))}
        <PrimaryHoverBox
          style={{ cursor: "pointer" }}
          justifyContent={"center"}
          alignItems={"center"}
          px={"sm"}
          py={"sm"}
          borderRadius={"sm"}
          onClick={shareStory}
        >
          <Image
            src={"/assets/images/svgimages/shareStoryLogo.svg"}
            alt={"share story logo"}
            width={40}
            height={40}
          />
          <Text size={["12px", "14px"]} pt={"sm"}>
            Story
          </Text>
        </PrimaryHoverBox>
      </Row>
    </Box>
  );
};
