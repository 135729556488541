import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import { OrgReviewPostType } from "@/utils/types";
import { useUser } from "@/hooks/useUser";
import { create } from "lodash";
import { BASE_COLORS } from "@/theme";
import { useRouter } from "next/navigation";
import yellowStar from "@/public/assets/icons/yellowStar.svg";
import greyStar from "@/public/assets/icons/greyStar.svg";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { PostStatus } from "@/gql/graphql";
import { useEffect, useRef, useState } from "react";
import { useFetchOrganization } from "@/hooks/useFetchOrganization";
import { logPostToAnalytics } from "@/lib/gtag";
import { CardHeader } from "@/components/Card/Components/CardHeader";
import { StoriesCardHeader } from "../Components/StoriesCardHeader";
export const ShareStoryOrganizationReview: React.FC<OrgReviewPostType> = ({
  school,
  schoolLogo,
  postBy,
  universityName,
  tags,
  createdAt,
  upVotes,
  downVotes,
  comments,
  savedPosts,
  viewCount,
  id,
  cardComments,
  authorId,
  status,
  isDeletedFeed,
  type,
  isSharedAsStory,
}) => {
  const { data } = useUser();
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [justDeleted, setJustDeleted] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const disableBlur = () => {
    setBlurEnabled(false);
  };

  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const alreadyUpVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "UPVOTE") return true;
      else return false;
    }
  );
  const alreadyDownVoted = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "DOWNVOTE") return true;
      else return false;
    }
  );
  const alreadyBookMarked = data?.me?.postActivities.find(
    (activity: { postId: string; type: string }) => {
      if (activity.postId === id && activity.type === "BOOKMARK") return true;
      else return false;
    }
  );

  const handlePostDelete = (PostId: string) => {
    if (PostId) setJustDeleted(true);
  };

  const reviewBoxValues = cardComments
    ? Object.values(cardComments).slice(1)
    : [];

  const intOnlyReviewBoxValues = reviewBoxValues.slice(0);

  const reviewBoxlabels = [
    "Friendliness",
    "Popularity",
    "Classiness",
    "Involvement",
    "Social Life",
    "Brotherhood",
  ];
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  const { data: taggedOrganization, loading: loadingTaggedOrg } =
    useFetchOrganization(
      school?.alias || "",
      cardComments?.organizationId || ""
    );

  const shortTaggedSchool = school?.name
    ? `${school.name.slice(0, 100)}${school.name.length > 100 ? "..." : ""}`
    : "";

  const shortTaggedOrg = taggedOrganization?.fetchOrganization
    ? `${taggedOrganization.fetchOrganization.name.slice(0, 100)}...`
    : "";

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect(); // Stop observing once the post is in the viewport
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );

  return (
    <Box
      ref={postRef}
      px={isSharedAsStory ? "xl" : ["md", "3xl"]}
      py="sm"
      //   borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
      // borderLeft={`1px solid ${BASE_COLORS.grey}`}
      position="relative"
      my={"md"}
      backgroundColor={isSharedAsStory ? "white" : "background"}
      borderRadius={"md"}
      width={"90%"}
    >
      {renderPremiumOverlayIfNeeded()}
      <StoriesCardHeader
        schoolLogo={schoolLogo}
        postBy={postBy}
        universityName={universityName}
        tags={tags}
        createdAt={createdAt}
        postId={id}
        authorId={authorId}
        handlePostDelete={enableBlur}
        isSharedAsStory={isSharedAsStory}
      />
      <Box
        py="md"
        color="black"
        // onClick={() => router.push(`/post/${id}`)}
        style={{ cursor: "pointer" }}
        gap={"md"}
      >
        {loadingTaggedOrg ? null : (
          <Row
            alignItems={"center"}
            gap={"md"}
            justifyContent={"start"}
            border={`1px solid ${BASE_COLORS.primary} `}
            borderRadius="full"
            width="fit-content"
            py="sm"
            px="sm"
            bg="primaryLightTransparent"
          >
            <Row gap={"sm"} alignItems={"center"} minWidth={"fit-content"}>
              <Image
                src={taggedOrganization?.fetchOrganization.logo || ""}
                alt={"Logo"}
                width={27}
                height={27}
                style={{ borderRadius: "25px" }}
              />
              <Text color="primary">{shortTaggedSchool}</Text>
            </Row>
            <Box
              height={20}
              width={"1px"}
              borderLeft={`1px solid ${BASE_COLORS.primary}`}
              bg={"primary"}
            ></Box>
            <Text color="primary" size={13}>
              {taggedOrganization?.fetchOrganization.name}
            </Text>
          </Row>
        )}
        <Text
          size={18}
          weight="bold"
          color={isSharedAsStory ? "black" : "foreground"}
        >
          {cardComments ? cardComments.text : ""}
        </Text>
        <Row gap={"sm"} flexWrap={"wrap"}>
          {reviewBoxlabels.map((label, index) => (
            <Box
              key={index}
              width={["130px", "150px"]}
              py={"sm"}
              px={"md"}
              gap={"xs"}
              bg={"primary"}
              borderRadius={"md"}
            >
              <Text
                // color="white"
                fontSize={[14, 16]}
                color={isSharedAsStory ? "white" : "foreground"}
              >
                {label}
              </Text>
              <Row gap={"xxs"}>
                {stars.map((star) => (
                  <Image
                    key={star}
                    width={18}
                    height={18}
                    src={
                      //@ts-ignore
                      star <= intOnlyReviewBoxValues[index]
                        ? yellowStar
                        : greyStar
                    }
                    alt="Star"
                  />
                ))}
              </Row>
            </Box>
          ))}
        </Row>
      </Box>
    </Box>
  );
};
