import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";

const FETCH_ORGANIZATION_DOCUMENT = graphql(`
  query FetchOrganization($input: FetchOrganizationInput!) {
    fetchOrganization(input: $input) {
      alias
      domain
      followerCount
      id
      logo
      name
      type
    }
  }
`);

export const useFetchOrganization = (
  schoolAlias: string,
  organizationId: string
) => {
  return useQuery(FETCH_ORGANIZATION_DOCUMENT, {
    variables: {
      input: {
        id: organizationId,
        schoolAlias,
      },
    },
  });
};
