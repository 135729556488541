import { Box, Row, CenterBox, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";

import { RankingPostPropsV2 } from "@/utils/types";

import { useUser } from "@/hooks/useUser";
import { useFetchOrganizationbyIds } from "@/hooks/useFetchOrganizationbyIds";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";

import { PostStatus } from "@/gql/graphql";
import { CardPremiumOverlay } from "@/components/CardPremiumOverlay";
import { logPostToAnalytics } from "@/lib/gtag";
import { SkeltonRankingPostV2 } from "@/app/(main)/plus/components/SkeletonRankingPostV2/SkeletonRankingPostV2";
import { StoriesCardHeader } from "../Components/StoriesCardHeader";

export const ShareStoryRankingV2: React.FC<RankingPostPropsV2> = ({
  schoolLogo,
  postBy,
  universityName,
  tags,
  type,
  cardComments,
  id,
  createdAt,
  authorId,
  isDeletedFeed,
  isSharedAsStory,
}) => {
  const { data } = useUser();
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const router = useRouter();
  const postRef = useRef<HTMLDivElement>(null);
  const [justDeleted, setJustDeleted] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(
    status === PostStatus.DeletedByPremiumUser
  );
  const disableBlur = () => {
    setBlurEnabled(false);
  };
  const enableBlur = () => {
    setBlurEnabled(true);
  };

  const { data: OrganizationData, loading } =
    useFetchOrganizationbyIds(organizationIds);
  const text =
    typeof cardComments === "object" && cardComments !== null
      ? (cardComments as { text?: string }).text || ""
      : "";

  const extractedMentions = tags?.map((item: any) => {
    return {
      tag: text.slice(item.startIndex, item.endIndex),
      mentionId: item.mentionedId,
      mentionType: item.mentionType,
      endIndex: item.endIndex,
      startIndex: item.startIndex,
    };
  });

  useEffect(() => {
    const fraternityIds = cardComments?.fraternityIds ?? [];
    const sororityIds = cardComments?.sororityIds ?? [];

    const allOrganizationIds = [...fraternityIds, ...sororityIds];

    setOrganizationIds(allOrganizationIds);
  }, [cardComments]);

  const getOrgData = (orgId: string) => {
    if (!OrganizationData?.fetchOrganizationsByIds)
      return { logo: "", name: "", alias: "", id: "", type: "" };
    return OrganizationData?.fetchOrganizationsByIds.find(
      (org) => org.id === orgId
    );
  };

  const renderPremiumOverlayIfNeeded = () => {
    if (isDeletedFeed) return null;
    if (status === PostStatus.DeletedByPremiumUser && blurEnabled) {
      return <CardPremiumOverlay disableBlur={disableBlur} />;
    } else return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logPostToAnalytics(id, type);
            observer.disconnect(); // Stop observing once the post is in the viewport
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the post is visible
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [id]);

  if (justDeleted)
    return (
      <CenterBox width="100%">
        <Text color="foreground" size={17} py="4xl">
          This post has been deleted successfully
        </Text>
      </CenterBox>
    );
  //   const handleOrganizationClick = (e: React.MouseEvent) => {
  //     e.stopPropagation();
  //     router.push(
  //       `/organization/${data?.me.school?.alias}/${OrganizationData?.fetchOrganizationsByIds[0].id}/overview`
  //     );
  //   };

  return (
    <>
      {loading && !isSharedAsStory ? (
        <SkeltonRankingPostV2 />
      ) : (
        <Box
          width={"90%"}
          borderBottom={`1px solid ${BASE_COLORS.lightGreyTransparent}`}
          px={isSharedAsStory ? "xl" : ["md", "3xl"]}
          py={["lg", "xl"]}
          position="relative"
          backgroundColor={isSharedAsStory ? "white" : "background"}
          borderRadius={"md"}
        >
          {renderPremiumOverlayIfNeeded()}
          <StoriesCardHeader
            schoolLogo={schoolLogo}
            postBy={postBy}
            universityName={universityName}
            tags={extractedMentions}
            createdAt={createdAt}
            postId={id}
            authorId={authorId}
            handlePostDelete={enableBlur}
            isSharedAsStory={isSharedAsStory}
          />

          <Box
            mt={"lg"}
            mb={"md"}
            // onClick={() => router.push(`/post/${id}`)}
            cursor="pointer"
          >
            <Text
              size={[18, 22]}
              py={"md"}
              color={isSharedAsStory ? "black" : "foreground"}
            >
              {cardComments?.text}
            </Text>
            {cardComments?.fraternityIds?.length! > 0 && (
              <Text
                size={[16, 20]}
                color={isSharedAsStory ? "black" : "foreground"}
              >
                Fraternity Ranking
              </Text>
            )}
            {cardComments?.fraternityIds &&
              cardComments.fraternityIds.length > 0 && (
                <Box
                  border={`1px solid ${BASE_COLORS.grey}`}
                  py="lg"
                  px="md"
                  width="100%"
                  borderRadius="md"
                  gap="lg"
                  my="md"
                >
                  {(isSharedAsStory
                    ? [cardComments.fraternityIds[0]]
                    : cardComments.fraternityIds
                  ).map((organization, index) => (
                    <Row key={index}>
                      <Row
                        border={`1px solid ${BASE_COLORS.grey}`}
                        py="sm"
                        px="md"
                        borderRadius="md"
                        alignItems="center"
                        gap="lg"
                        width="100%"
                      >
                        <Box
                          border={`1px solid ${BASE_COLORS.primary}`}
                          borderRadius="full"
                          width={30}
                          height={30}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            backgroundImage={`url(${
                              getOrgData(organization)?.logo
                            })`}
                            width={25}
                            height={25}
                            backgroundRepeat="no-repeat"
                            backgroundSize="contain"
                            backgroundPosition="center"
                          />
                        </Box>
                        <Text color={isSharedAsStory ? "black" : "foreground"}>
                          {getOrgData(organization)?.name}
                        </Text>
                      </Row>
                    </Row>
                  ))}
                </Box>
              )}
            {cardComments?.sororityIds?.length! > 0 && (
              <Text
                size={[16, 20]}
                color={isSharedAsStory ? "black" : "foreground"}
              >
                Sorority Ranking
              </Text>
            )}
            {cardComments?.sororityIds &&
            cardComments?.sororityIds?.length! > 0 ? (
              <Box
                border={`1px solid ${BASE_COLORS.grey}`}
                py={"lg"}
                px={"md"}
                width={"100%"}
                borderRadius={"md"}
                gap={"lg"}
                my={"md"}
              >
                {(isSharedAsStory
                  ? [cardComments.sororityIds[0]]
                  : cardComments?.sororityIds
                ).map((organization, index) => (
                  <Row key={index}>
                    <Row
                      border={`1px solid ${BASE_COLORS.grey}`}
                      py={"sm"}
                      px={"md"}
                      borderRadius={"md"}
                      alignItems={"center"}
                      gap={"lg"}
                      //   onClick={handleOrganizationClick}
                      cursor="pointer"
                      width={"100%"}
                    >
                      <Box
                        border={`1px solid ${BASE_COLORS.primary}`}
                        borderRadius={"full"}
                        width={30}
                        height={30}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Box
                          backgroundImage={`url(${
                            getOrgData(organization)?.logo
                          })`}
                          width={25}
                          height={25}
                          backgroundRepeat={"no-repeat"}
                          backgroundSize={"contain"}
                          backgroundPosition={"center"}
                        ></Box>
                      </Box>

                      <Text color={isSharedAsStory ? "black" : "foreground"}>
                        {" "}
                        {getOrgData(organization)?.name}
                      </Text>
                    </Row>
                  </Row>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
};
