import { graphql } from "@/gql";
import { gql, useMutation } from "@apollo/client";

export const SHARE_POST_AS_STORY = graphql(`
  mutation SharePostAsStory($input: SharePostAsStoryInput!) {
    sharePostAsStory(input: $input) {
      id
      content
      createdAt
      expiresAt
      type
    }
  }
`);

export const useSharePostAsStory = () => {
  return useMutation(SHARE_POST_AS_STORY);
};
