"use client";
import { Box } from "@/components/styled";
import { useGetPostDetails } from "@/hooks/useGetPostDetails";
import { useRouter, useSearchParams } from "next/navigation";
import { LoadingScreen } from "@/components/Loading";
import { ShareStoryPosts } from "../ShareStoryPosts";
import { Spinner } from "@/components/Spinner";

export const ShareStory = ({
  postId,
  isDeletedFeed,
}: {
  postId: string;
  isDeletedFeed?: boolean;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const replyId = searchParams.get("replyId");
  const { data, loading, error, refetch } = useGetPostDetails(postId);
  const extractedPost = data?.fetchPostDetail;

  //   if (loading) {
  //     return (
  //       <Box width={25} height={25}>
  //         <Spinner color="primary" />
  //       </Box>
  //     );
  //   }

  return (
    <Box>
      <ShareStoryPosts
        title={extractedPost?.title}
        hashtags={extractedPost?.hashtags}
        username={extractedPost?.nickname}
        id={extractedPost?.id}
        schoolLogo={extractedPost?.school?.logo}
        universityName={extractedPost?.school?.alias}
        tags={extractedPost?.mentions}
        postBy={extractedPost?.nickname}
        views={2}
        upVotes={extractedPost?.upvoteCount}
        downVotes={extractedPost?.downvoteCount}
        comments={extractedPost?.replyCount}
        sharedPosts={2}
        savedPosts={extractedPost?.bookmarkCount}
        type={extractedPost?.type}
        cardComments={extractedPost?.content}
        replies={extractedPost?.replies}
        createdAt={extractedPost?.createdAt}
        replyId={replyId || ""}
        authorId={extractedPost?.authorId}
        status={extractedPost?.status}
        viewCount={extractedPost?.viewCount}
        isDeletedFeed={isDeletedFeed}
      />
    </Box>
  );
};
