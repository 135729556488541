import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";

export const USER_DOCUMENT = graphql(`
  query Me {
    me {
      activePremiumPlan {
        name
      }
      schoolId
      isPremium
      postActivities {
        type
        postId
        id
      }
      pollVoteActivities {
        id
        pollOptionId
        postId
      }
      phoneNumber
      stripeCustomerId
      id
      school {
        id
        domain
        coverImage
        location
        logo
        name
        alias
      }
      email
      countryCode
      blacklisted
      follows {
        followableId
        followableType
        id
      }
      activePremiumPlan {
        id
        name
        showOnApp
        stripePriceId
        createdAt
        price
        validityInDays
        type
      }
      subscriptions {
        allottedDeleteCount
        allottedRestoreCount
        deleteCount
        restoreCount
        startDate
        id
        planId
        stripeSubscriptionId
        status
        plan {
          id
          name
          showOnApp
          stripePriceId
          createdAt
          price
          validityInDays
        }
      }
      deletedUsingPremiumSubscription {
        id
        type
        authorId
        schoolId
        nickname
        title
        parentId
        content
        status
        replyCount
        upvoteCount
        downvoteCount
        bookmarkCount
        createdAt
        updatedAt
        deletedAt
        school {
          id
          domain
          coverImage
          location
          logo
          name
        }
        parentId
        mentions {
          endIndex
          mentionType
          mentionedId
          postId
          schoolIdContext
          startIndex
          id
        }
        hashtags {
          endIndex
          id
          postId
          startIndex
          tag
        }
      }
    }
  }
`);

export const useUser = () => {
  const response = useQuery(USER_DOCUMENT, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  return response;
};
