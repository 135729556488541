import { Box, Button, Text } from "@/components/styled";
import { useEffect, useState } from "react";
import { BASE_COLORS } from "@/theme";
import { useUser } from "@/hooks/useUser";
import { Spinner } from "@/components/Spinner";
import { LoadingScreen } from "@/components/Loading";
import { InfiniteLoading } from "@/components/InfiniteLoading";
import { useRestorePost } from "@/hooks/useRestorePost";
import toast from "react-hot-toast";
import { getMessageFromError } from "@/utils/getMessageFromError";
import { PostStatus } from "@/gql/graphql";
import { useSearchPosts } from "@/hooks/useSearchPosts";
import Link from "next/link";

export type RestorePostForm = {
  id: string;
  toggleModal: () => void;
};
export const RestorePostForm = ({ id, toggleModal }: RestorePostForm) => {
  const { data, loading } = useUser();
  const { restorePostByPremiumPlusUser, error } = useRestorePost();
  const { refetch } = useSearchPosts({
    keywords: "",
    status: PostStatus.DeletedByPremiumUser,
  });

  const allotedRestoreCount =
    data?.me?.subscriptions[0]?.allottedRestoreCount ?? 0;
  const restoreCount = data?.me?.subscriptions[0]?.restoreCount ?? 0;
  const [buttonSelected, setButtonSelected] = useState("Cancel");
  const handleRestore = async () => {
    try {
      const results = await toast.promise(
        restorePostByPremiumPlusUser({
          variables: {
            restorePostInput: {
              postId: id,
            },
          },
        }),
        {
          loading: "Restoring Post",
          error: "Something went wrong",
          success: "Post Restored Successfully",
        }
      );
      toggleModal();
      refetch();
    } catch (error: any) {
      toast.error(`${getMessageFromError(error)}`);
    }
  };

  const handleButtonSelected = (buttonName: string) => {
    setButtonSelected(buttonName);

    buttonName === "Cancel" ? toggleModal() : handleRestore();
  };
  if (loading) {
    return <InfiniteLoading />;
  }
  const restore_count = allotedRestoreCount - restoreCount;
  return (
    <Box gap="lg" pt={"2xl"}>
      <Text
        size={["40px", "50px"]}
        weight="bold"
        color="primary"
        textAlign="center"
      >
        Restore Post?
      </Text>
      <Text size={["14px", "18px"]} color="grey" textAlign="center">
        As a premium user, you have the privilege to restore deleted posts. Are
        you sure you want to proceed with this restore?
      </Text>
      <Text size={["14px", "18px"]} color="grey" textAlign="center">
        You have <Text color="primary">{restore_count}</Text> restore options
        left. {restore_count > 0 && <Text color="grey"> Use them wisely!</Text>}
      </Text>

      {restore_count > 0 ? (
        <Box
          gap="md"
          justifyContent="center"
          my="7xl"
          flexDirection={["column", "row"]}
          px={["xl", "none"]}
        >
          {["Restore Post", "Cancel"].map((buttonName, index) => (
            <Button
              key={index}
              px={["none", "7xl"]}
              py="md"
              backgroundColor={
                buttonSelected === buttonName ? "primary" : "white"
              }
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius="md"
              onClick={() => handleButtonSelected(buttonName)}
            >
              <Text color={buttonSelected === buttonName ? "white" : "primary"}>
                {buttonName}
              </Text>
            </Button>
          ))}
        </Box>
      ) : (
        <Link href={"/premium"} style={{ textDecoration: "none" }}>
          <Box my="7xl" alignItems={"center"}>
            <Button
              py={10}
              px={25}
              backgroundColor={BASE_COLORS.primary}
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius={"5px"}
              width={"25vw"}
            >
              <Text color="white">Go to Premium</Text>
            </Button>
          </Box>
        </Link>
      )}
    </Box>
  );
};
