import { Box, Row, Column, CenterBox, Text } from "@/components/styled";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";

export const SkeltonRankingPostV2: React.FC = () => {
  return (
    <Box width={"100%"} height={"100%"} px="lg">
      <CenterBox
        justifyContent="start"
        flexDirection="row"
        height="fit-content"
        gap="lg"
        py="md"
      >
        <Skeleton height={50} width={50} borderRadius={200} />
        <Column gap="lg">
          <Row gap="sm">
            <Skeleton width={70} height={25} borderRadius={200} />
            <Skeleton width={5} height={5} borderRadius={200} />
            <Skeleton width={70} height={25} />
            <Skeleton width={70} height={25} />
          </Row>
          <Row gap="md">
            <Skeleton width={50} height={25} />
            <Skeleton width={70} height={25} />
            <Skeleton width={70} height={25} />
          </Row>
        </Column>
      </CenterBox>
      <Skeleton width={450} height={25} borderRadius={200} />
      <Box py={"md"}>
        <Box py={"sm"}>
          <Skeleton width={80} height={25} />
        </Box>

        <Skeleton width={380} height={25} borderRadius={200} />
        <Skeleton width={380} height={25} borderRadius={200} />
        <Skeleton width={380} height={25} borderRadius={200} />
      </Box>
      <Box>
        <Box py={"sm"}>
          <Skeleton width={80} height={25} />
        </Box>
        <Skeleton width={380} height={25} borderRadius={200} />
        <Skeleton width={380} height={25} borderRadius={200} />
        <Skeleton width={380} height={25} borderRadius={200} />
      </Box>
    </Box>
  );
};
