import { graphql } from "@/gql";
import { useMutation, useQuery } from "@apollo/client";

export const FLAG_POST = graphql(`
  mutation FlagPost($input: FlagPostInput!) {
    flagPost(input: $input)
  }
`);

export const useFlagPost = () => {
  const [flagPost, { data, loading, error }] = useMutation(FLAG_POST);
  return {
    flagPost,
    data,
    loading,
    error,
  };
};
